import { useContext } from 'react';
import { ExperimentsContext } from '../Context/ExperimentsContext';

export const useExperimentsContext = () => {
    const context = useContext(ExperimentsContext)

    if (!context) {
        throw Error('useExperimentsContext must be used inside an ExperimentsContextProvider')
    }

    return context
}