import React from 'react';
import OkRename from '../../Images/rename.png';

const Rename = ( {setRename, setPop, changelang, inputText, setInputText, red, setRed, visible, setVisible, 
    category, categories, setCategories} ) => {
        const inputTextHandler = (e) => {
            setInputText(e.target.value);

            setRed(false);
        };

        const selectText = (e) => {
            e.target.select();
        };
        
        const resetForm = (e) => {
            setVisible(false);
            e.target.removeAttribute("id");
            if (inputText === "") {
                setRed(false);
            };
        };

        const pressedKey = (e) => {
            if (e.key === 'Escape') {
                setRename(false);
            };
            if (e.key === 'Enter') {
                e.target.blur();
                submitText(e);
            };
        };

        const error = (type) => {
            setRed(true);
            if (type === 2) {
                setVisible(true);
            };
        };

        const submitText = (e) => {
            e.preventDefault();
            document.getSelection().removeAllRanges();

            var text= inputText.replace(/\s+/g,' ').trim();
            
            if (text === "") {
                error(1);
                setInputText(text);
            }
            else {
                var checkValues= [];
                Object.values(categories).map((category) => {
                    return checkValues= [...checkValues, category.name];
                });
                if (text === category.name) {
                    setRename(false);
                    return;
                }
                else if (checkValues.includes(text)) {
                    error(2);
                    setInputText(text);
                }
                else {
                    setRename(false);
                    const newCategory= {...category};
                    newCategory.name= text;
                    setCategories({...categories, [category.id]: newCategory});
                    
                    setPop(5);
                };
            };
        };

        return (
            <div className= "categorytitleformcontainer">
                <input className= "categorytitleform" type= "text" 
                    placeholder= {changelang ? "Input new name..." : "Εισαγωγή νέου ονόματος..."}
                    id= {(red? 'placeholder' : 'none')} title= {inputText} value= {inputText} 
                    data-inrename= {true} autoComplete= "off" autoFocus
                    onChange= {inputTextHandler} onFocus= {selectText}
                    onPointerDown= {resetForm} onKeyDown= {pressedKey}
                    style= {{color: (red? 'red' : 'black'), borderColor: (red? 'red' : 'black')}} />
                {visible && <div id= "tooltipcategorytitleform" data-inrename= {true}>
                    {changelang ? "Category name already exists!" : "Το όνομα υπάρχει ήδη!"}
                </div>}
                <div id= "okrename" data-inrename= {true}>
                    <img src= {OkRename} alt= "Ok Icon" title= {changelang ? "Rename" : "Αλλαγή"} data-inrename= {true} 
                        onClick= {submitText} style= {{width: '16px', height: '16px'}} />
                </div>
            </div>
        );
}

export default Rename;