import React from 'react';
import Pin from '../../Images/pin.png';

const Features = ( {changelang} ) => {
    return (
        <div className= "features">
            <img src= {Pin} alt= "Pin Icon" />
            <div className= "featurestext">
                <div>
                    {changelang ? "" : "Το "}
                    <b>Sort-it!</b>
                    {changelang ?
                        "offers the capabilities of a Card Sorting tool"
                    :
                        "προσφέρει τις δυνατότητες ενός εργαλείου Card Sorting"
                    }. 
                    <br />
                    <br />
                    {changelang ?
                        "A special feature is the creation of a user account that gives access to the basic features of the application. These are creating and editing card sorting experiments. The creator can give specific instructions before and after conducting the experiment, allow for unsorted cards, and set a time limit for the availability of responses"
                    :
                        "Ειδικό χαρακτηριστικό είναι η δημιουργία λογαριασμού χρήστη που δίνει πρόσβαση στα βασικά χαρασκτηριστικά της εφαρμογής. Αυτά είναι η δημιουργία και επεξεργασία πειραμάτων ταξινόμησης καρτών. Ο δημιουργός μπορεί να δώσει συγκεκριμένες οδηγίες πριν και μετά τη διεξαγωγή του πειράματος, να επιτρέψει να υπάρξουν μη ταξινομημένες κάρτες, καθώς και να θέσει χρονικό όριο διαθεσιμότητας υποβολής απαντήσεων"
                    }.
                    <br />
                    <br />
                </div>
                <div style= {{textAlign: 'center'}}>
                    {changelang ? "Two types of sorting are supported" : "Υποστηρίζονται δυο είδη ταξινόμησης"}:
                </div>
                <div className= "types">
                    <div id= "type1">
                        <div style= {{textAlign: 'center', textDecoration: 'underline'}}>
                            {changelang ? "Open Card Sorting" : "Ανοιχτό Card Sorting"}:
                        </div>
                        <br />
                        {changelang ?
                            "Participants create their own names for the categories. This reveals not only how they mentally sort the cards, but also what terms they use to describe their categorization"
                        :
                            "Oι συμμετέχοντες δημιουργούν τα δικά τους ονόματα για τις κατηγορίες. Αυτό αποκαλύπτει όχι μόνο πως ταξινομούν διανοητικά τις κάρτες, αλλά και ποιους όρους χρησιμοποιούν για να περιγράψουν την κατηγοριοποίηση τους"
                        }.
                    </div>
                    <div id= "type2">
                        <div style= {{textAlign: 'center', textDecoration: 'underline'}}>
                            {changelang ? "Close Card Sorting" : "Κλειστό Card Sorting"}:
                        </div>
                        <br />
                        {changelang ?
                            "A predefined set of named categories is provided. Participants assign the various cards to these fixed categories. This reveals the extent to which participants agree on which cards belong to each category"
                        :
                            "Παρέχεται ένα προκαθορισμένο σύνολο ονοματισμένων κατηγοριών. Οι συμμετέχοντες εκχωρούν τις διάφορες κάρτες σε αυτές τις σταθερές κατηγορίες. Αυτό αποκαλύπτει το βαθμό στον οποίο οι συμμετέχοντες συμφωνούν σχετικά με το ποιες κάρτες ανήκουν σε κάθε κατηγορία"
                        }.
                    </div>
                </div>
                <br />
                <br />
                <div>
                    {changelang ?
                        "Finally, it is possible to export the results to be analyzed by a specialized tool. Such analysis tools are now available for free and in order to provide as much support as possible, there are different data export formats (csv, txt)"
                    :
                        "Τέλος, δίνεται η δυνατότητα εξαγωγής των αποτελεσμάτων για να γίνει ανάλυση τους από εξειδικευμένο εργαλείο. Πλέον διατίθονται δωρεάν τέτοια εργαλεία ανάλυσης και προκειμένου να υπάρξει όσον το δυνατόν μεγαλύτερη υποστήριξη, υπάρχουν διαφορετικές μορφές εξαγωγής των δεδομένων (csv, txt)"
                    }.
                </div>
            </div>
        </div>
    )
}

export default Features;