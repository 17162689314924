import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Pin from '../../Images/pin.png';
import PinGreen from '../../Images/pingreen.png';
import PinBlue from '../../Images/pinblue.png';

const noteVariants = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
    },
    transition: { duration: 0.5 }
}

const Board = ( {changelang} ) => {
    const [whatis, setWhatis] = useState(false);
    const [whatbenefits, setWhatbenefits] = useState(false);
    const [whichtype, setWhichtype] = useState(false);

    return (
        <div className= "board">
            <motion.div layout className= "mediumpostit" id= "whatis" onClick= {() => setWhatis(!whatis)} 
                data-isopen= {whatis} >
                    <motion.img layout src= {Pin} alt= "Pin Icon" />
                    <motion.div layout className= "notetext" data-isopen= {whatis}>
                        {changelang ? "What is Card Sorting?": "Τι είναι το Card Sorting;"}
                    </motion.div>
                        {whatis && <motion.div layout className= "note" {...noteVariants}>
                            {changelang ? 
                                "Card sorting is a method used to help design or evaluate the information architecture of a site. Participants organize topics into categories that make sense to them and they may also label these groups depending on the experiment."
                            :
                                "Το Card Sorting είναι μια μέθοδος που χρησιμοποιείται για να βοηθήσει στο σχεδιασμό ή την αξιολόγηση ενός συστήματος. Οι συμμετέχοντες οργανώνουν αντικείμενα σε κατηγορίες που έχουν νόημα για αυτούς και μπορεί επίσης να ονοματίσουν αυτές τις ομάδες ανάλογα με το πείραμα."
                            }
                            <br />
                            {changelang ?
                                "In a user-centered design process, it is typically used when developing a website's information and navigation architecture, but it is also applied to the development of workflows, menu structure, toolbars, and other elements of a system's design."
                            :
                                "Σε μια διαδικασία σχεδίασης με επίκεντρο το χρήστη, χρησιμοποιείται συνήθως κατά την ανάπτυξη της αρχιτεκτονικής πληροφοριών και πλοήγησης ενός ιστοτόπου, αλλά εφαρμόζεται επίσης στην ανάπτυξη ροών εργασίας, μενού, γραμμών εργαλείων και άλλων στοιχείων του σχεδιασμού ενός συστήματος."
                            }
                            <br />
                            {changelang ?
                                "For example it can be used on a website:"
                            :
                                "Για παράδειγμα μπορεί να χρησιμοποιηθεί σε ένα ιστότοπο:"
                            }
                            <br />
                            ~ {changelang ?
                                "to create the home page."
                            :
                                "για τη δημιουργία της αρχικής σελίδας."
                            }
                            <br />
                            ~ {changelang ?
                                "to name the categories and navigation routes."
                            :
                                "για την ονομάτιση κατηγοριών και διαδρομών πλοήγησης."
                            }
                            <br />
                            ~ {changelang ?
                                "to add new elements to an already existing structure."
                            :
                                "για την προσθήκη καινούριων αντικειμένων σε ήδη υπάρχουσα δομή."
                            }
                    </motion.div>}
            </motion.div>
            <motion.div layout className= "mediumpostit" id= "whatbenefits" data-isopen= {whatbenefits}
                onClick= {() => setWhatbenefits(!whatbenefits)} >
                    <motion.img layout src= {PinGreen} alt= "Pin Icon" />
                    <motion.div layout className= "notetext" data-isopen= {whatbenefits}>
                        {changelang ? "What are the benefits of Card Sorting?": "Ποια είναι τα οφέλη του Card Sorting;"}
                    </motion.div>
                    {whatbenefits && <motion.div layout className= "note" {...noteVariants}>
                        {changelang ?
                            "Card Sorting helps to understand user expectations and how they perceive different topics. It is useful for:"
                        :
                            "Το Card Sorting βοηθάει στην κατανόηση των προσδοκιών των χρηστών και πως αντιλαμβάνονται τα διάφορα θέματα. Είναι χρήσιμο για τα εξής:"
                        }
                        <br />
                        ~{changelang ?
                            "Design or redesign a website or application"
                        :
                            "Σχεδιασμό ή επανασχεδιασμό της δομής ενός ιστοτόπου ή μιας εφαρμογής"
                        }.
                        <br />
                        ~{changelang ?
                            "Develop the information and navigational architecture for a website or application"
                        :
                            "Ανάπτυξη της αρχιτεκτονικής πληροφοριών και πλοήγησης ενός ιστοτόπου ή μιας εφαρμογής"
                        }.
                        <br />
                        ~{changelang ?
                            "Organize icons, images, menu items, and other objects into related groups"
                        :
                            "Οργάνωση εικονιδίων, εικόνων, στοιχείων μενού και άλλων αντικειμένων σε σχετικές ομάδες"
                        }.
                        <br />
                        ~{changelang ?
                            "Examine how different people view the same subject matter"
                        :
                            "Εξέταση πως διαφορετικοί άνθρωποι κατανοούν το ίδιο θέμα"
                        }.
                        <br />
                        ~{changelang ?
                            "Grouping objects based on defined criteria"
                        :
                            "Ομαδοποίηση αντικειμένων με βάση καθορισμένα κριτήρια"
                        }.
                    </motion.div>}
            </motion.div>
            <motion.div layout className= "mediumpostit" id= "whichtype" onClick= {() => setWhichtype(!whichtype)} 
                data-isopen= {whichtype} >
                    <motion.img layout src= {PinBlue} alt= "Pin Icon" />
                    <motion.div layout className= "notetext" data-isopen= {whichtype}>
                        {changelang ? "Which type of Card Sorting do I use?": "Ποιο τύπο Card Sorting να χρησιμοποιήσω;"}
                    </motion.div>
                    {whichtype && <motion.div layout className= "note" {...noteVariants}>
                        <span style= {{textDecoration: 'underline'}}>
                            {changelang ? "Open type" : "Ανοιχτός τύπος:"}
                        </span>
                        {changelang ? " (usually design)" : " (συνήθως ανάπτυξη)"}
                        <br />
                        {changelang ?
                            "It is used to discover patterns in how participants classify, which in turn helps generate ideas for organizing information"
                        :
                            "Χρησιμοποιείται για την ανακάλυψη μοτίβων στον τρόπο ταξινόμησης των χρηστών και έτσι συμβάλλει στη δημιουργία ιδεών για την οργάνωση πληροφοριών"
                        }.
                        <br />
                        <br />
                        <span style= {{textDecoration: 'underline'}}>
                            {changelang ? "Close type" : "Κλειστός τύπος:"}
                        </span>
                        {changelang ? " (usually evaluation)" : " (συνήθως αξιολόγηση)"}
                        <br />
                        {changelang ?
                            "It is used to judge whether a given set of category names provides an effective way to organize a given collection of content"
                        :
                            "Χρησιμοποιείται για να κρίνει αν ένα υπάρχον σύνολο ονομασμένων κατηγοριών παρέχει έναν αποτελεσματικό τρόπο οργάνωσης μιας δεδομένης συλλογής περιεχομένου"
                        }.
                        <br />
                    </motion.div>}
            </motion.div>
        </div>
    )
}

export default Board;