import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Dashboard from './Components/Dashboard/Dashboard';
import ChooseSort from './Components/Sort/ChooseSort';
import NotExperiment from './Components/Errors/NotExperiment';
import { ExperimentContextProvider } from './Context/ExperimentContext';
import { ResultsContextProvider } from './Context/ResultsContext';
import { UserContextProvider } from './Context/UserContext';

function App() {
    return (
        <div className= "App">
            <BrowserRouter>
                <ExperimentContextProvider>
                    <ResultsContextProvider>
                        <UserContextProvider>
                            <Routes>
                                <Route path= "/*" element= {<Home />} />
                                <Route path= "/dashboard/*" element= {<Dashboard />} />
                                <Route path= "/sort/:id/*" element= {<ChooseSort />} />
                                <Route path= "/notfound" element= {<NotExperiment />} />
                            </Routes>
                        </UserContextProvider>
                    </ResultsContextProvider>
                </ExperimentContextProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;