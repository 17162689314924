import React, { useEffect, useState } from 'react';
import { useExperimentContext } from '../../Hooks/useExperimentContext';
import DateISO from '../functions/DateISO';

const Options = ( {setPop, changelang} ) => {
    const {experiment, dispatch} = useExperimentContext();

    const [inputTasks, setInputTasks] = useState(experiment.instructions);
    const [inputBye, setInputBye] = useState(experiment.goodbye[1]);
    const [redBye, setRedBye] = useState(false);

    var Byeoption;

    useEffect(() => {
        if (experiment.datestart) {
            const now= DateISO();
            const d1= now >= experiment.datestart;
            const d2= now <= experiment.datestop;

            if (d1 && d2) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: {publish: true}});
            }
            else {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: {publish: false}});
            };
        };
    }, [dispatch, experiment.datestart, experiment.datestop]);

    const inputTasksHandler = (e) => {
        setInputTasks(e.target.value);
    };
    
    const inputByeHandler = (e) => {
        setInputBye(e.target.value);
        setRedBye(false);
    };

    const check = (e) => {
        var el = document.querySelector('input[name="byemessage"]:checked');
        if (((el === null) && (inputBye === "")) || ((el !== null) && (inputBye !== ""))) {
            e.preventDefault();
            setRedBye(true);
        };
    };

    const changedata = async (e) => {
        e.preventDefault();
        
        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            var el = document.querySelector('input[name="byemessage"]:checked');
            if (el === null ) {
                Byeoption= false;
            }
            else {
                Byeoption= true;
            };
            
            const nexperiment = {instructions: inputTasks, goodbye: [Byeoption, inputBye.trim()]};
    
            const response = await fetch(`/api/dashboard/${experiment._id}`, {
                method: 'PATCH',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();
    
            if (response.ok) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: json});

                setInputBye(inputBye.trim());
                setPop(6);
            }
            else {
                setPop(15);
            };
        };
    };

    return (
        <form className= "options" id= "containerdscrollbar" onSubmit= {changedata}>
            <div className= "optionscontainer">
                <div className= "thetextareas">
                    <div className= "optionarea" id="tasksside">
                        <div className= "optionstitle">
                            {changelang ? "Experiment Instructions:" : "Οδηγίες πειράματος:"}
                        </div>
                        <textarea className= "taskstextarea" id= "containerdscrollbar" 
                            placeholder= {`Ελληνικά\n...\n\nEnglish\n...`} 
                            title= {inputTasks} value= {inputTasks} autoComplete= "off" onChange= {inputTasksHandler} 
                            disabled= {experiment.publish} >
                        </textarea>
                        <div id= "tasksinfo">
                        {changelang ? 
                            "*The experiment instructions will be displayed to the participants in a separate tab that they can keep open throughout the experiment if they wish. The available languages are Greek and English, so depending on who you are addressing to you can choose to give your instructions in one or both languages." 
                        :
                            "*Οι οδηγίες πειράματος θα εμφανιστούν στους συμμετέχοντες σε μια ξεχωριστή καρτέλα που μπορούν να κρατήσουν ανοιχτή καθ'όλη τη διάρκεια της διεξαγωγής του πειράματος αν επιθυμούν. Οι διαθέσιμες γλώσσες είναι Ελληνικά και Αγγλικά, οπότε ανάλογα με το που απευθύνεστε μπορείτε να επιλέξετε να δώσετε τις οδηγίες σας σε μια ή και στις δυο γλώσσες."
                        }
                        </div>
                    </div>
                    <div className= "optionarea" id= "thankyouside">
                        <div className= "optionstitle">
                            <span style= {{color: 'red'}}>*</span>
                            <span  id= "endtitle">
                                {changelang ? "At the end of the experiment" : "Στο τέλος του πειράματος"}
                            </span>
                        </div>
                        <div className= "bye">
                            <input type="checkbox" id="byemessage" name="byemessage" value="byemessage" 
                                defaultChecked= {experiment.goodbye[0]} disabled= {experiment.publish} 
                                onClick= {() => setRedBye(false)} />
                            <label htmlFor= "byemessage" style= {{color: (redBye? 'red' : 'black')}}>
                            {changelang ? "Display ending message." : "Εμφάνιση μηνύματος τέλους."}
                            </label>
                        </div>
                        <div className= "optionstitle">{changelang ? "or" : "ή"}</div>
                        <div id= "byelinktitle">
                            {changelang ? "Set website link to redirect:" : "Ορισμός ιστοσελίδας για μετάβαση:"}
                        </div>
                        <input className= "expform" type= "text"
                            title= {inputBye} value= {inputBye} autoComplete= "off" onChange= {inputByeHandler}
                            style= {{borderColor: (redBye? 'red' : 'black')}} disabled= {experiment.publish} />
                        {redBye && <div id= "errormessage" style= {{marginTop: "10px" , justifySelf: "flex-start"}}>
                            {changelang ? "*Choose one of the two options!*" : "*Επιλέξτε μια από τις δυο επιλογές!*"}
                        </div>}
                    </div>
                </div>
                <div className= "generalbutton">
                    <input type= "submit" className= "savebutton" value= {changelang ? "Save" : "Αποθήκευση"} 
                        onClick= {check} disabled= {experiment.publish} />
                </div>
            </div>
        </form>
    )
}

export default Options;