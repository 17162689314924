import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Logo from '../../Images/logo2.png';
import Greek from '../../Images/greekflag.png';
import UK from '../../Images/ukflag.png';
import Plus from '../../Images/whiteplus.png';
import User from '../../Images/user.png';
import { useUserContext } from '../../Hooks/useUserContext';

const userVariants= {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    },
    exit: {
        opacity: 0
    },
    transition: { duration: 0.2 }
}

const Header = ( {setSure, changelang, setChangelang} ) => {
    const { user } = useUserContext();

    // const [changelang, setChangelang] = useState(false);

    // const [dispuser, setDispuser] = useState(null);
    // const [username, setUsername] = useState(null);
    
    const [uservisible, setUservisible] = useState(false);

    // useEffect(() => {
        // const fetchUser = async () => {
        //     const response = await fetch('/api/dashboard/settings');
        //     const json = await response.json();

        //     if (response.ok) {
        //         dispatchU({type: 'SET_USER', payload: json})
        //     };
        // };

        // fetchUser();
    // }, []);

    document.body.onpointerdown= (e) => {
        if (e.target.getAttribute('data-inprof')) {
            return;
        };
        setUservisible(false);
    };

    const changeLanguage = () => {
        changelang ? setChangelang(false) : setChangelang(true);
    };

    const useroptions = (e) => {
        setUservisible(!uservisible);
        
        if (e.target.id === 'logout') {
            setSure(1);
        };
    };

    return (
        <div className= "header d">
            <Link to= {user ? "/dashboard" : "/home"}>
                <div id= "logod">
                    <img src= {Logo} alt= "Logo Icon" id= "icond" />
                </div>
            </Link>
            {user && <div className= "createnewcont">
                <Link to= "/dashboard/create">
                    <div className= "createnew">
                        <img src= {Plus} alt= "Create New Icon" />
                        <div id= "createnewtext">{changelang ? "New Experiment!" : "Νέο πείραμα!"}</div>
                    </div>
                </Link>
            </div>}
            {user && <div className= "connecteduser">
                <div className= "conuser" onClick= {useroptions} data-inprof= {true}>
                    <img src= {User} alt= "User Icon" data-inprof= {true} />
                    <div id= "conusername" data-inprof= {true}>
                        {user.name}
                    </div>
                </div>
                <AnimatePresence>
                    {uservisible && <motion.div className= "useroptions" data-inprof= {true} {...userVariants}>
                        <Link to= "/dashboard/settings">
                            <div className= "useroption" id= "usersettings" data-inprof= {true} onClick= {useroptions}>
                            {changelang ? "Settings" : "Ρυθμίσεις"}
                            </div>
                        </Link>
                        <div className= "useroption" id= "logout" data-inprof= {true} onClick= {useroptions}>
                        {changelang ? "Logout" : "Αποσύνδεση"}
                        </div>
                    </motion.div>}
                </AnimatePresence>
            </div>}
            <span id= "languaged" onClick= {changeLanguage} lang= {changelang ? "greek" : "english"} 
                title= {changelang ? "Change language" : "Αλλαγή γλώσσας"} >
                    {changelang ? 
                        <img src= {Greek} alt= "Greek Flag Icon" lang= "greek" /> 
                    : 
                        <img src= {UK} alt= "UK Flag Icon" lang= "english" />
                    }
            </span>
        </div>
    )
}

export default Header;