import React, { createContext, useReducer } from 'react';

export const UserContext = createContext()

export const userReducer = (stateU, actionU) => {
    switch (actionU.type) {
        case 'SET_USER':
            return {
                user: actionU.payload
            }
        case 'UPDATE_USER':
            return {
                user: {...stateU.user, ...actionU.payload}
            }
        default:
            return stateU
    }
}

export const UserContextProvider = ({children}) => {
    const [stateU, dispatchU] = useReducer(userReducer, {
        user: null
    })

    // console.log('UserContext state', stateU)

    return (
        <UserContext.Provider value= {{...stateU, dispatchU}}>
            {children}
        </UserContext.Provider>
    )
}
