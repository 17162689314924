import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import Pin from '../../Images/pinblue.png';
import Show from '../../Images/show.png';
import Hide from '../../Images/hide.png';
import { useReset } from '../../Hooks/useReset';

const Reset = ( {setPop, changelang} ) => {
    const { token } = useParams();

    const { reset, isLoading, error } = useReset();

    const [inputPass, setInputPass] = useState("");
    const [inputPassConf, setInputPassConf] = useState("");
    
    const [line, setLine] = useState(false);
    const [lineConf, setLineConf] = useState(false);
    const [eye, setEye] = useState(true);
    const [eyeConf, setEyeConf] = useState(true);
    
    const [pasCheck, setPasCheck] = useState(false);
    const [pasConf, setPasConf] = useState(false);

    const [redPas, setRedPas] = useState(false);
    const [redPasConf, setRedPasConf] = useState(false);

    useEffect(() => {
        const fetchToken = async () => {
            const response = await fetch('/get-token');
            const json = await response.json();

            if (!response.ok) {
                window.location.assign('/expired');
            };
        };

        fetchToken();
    }, []);

    useEffect(() => {
        const end= document.querySelector('#psw');
        if (end) {
            var len = end.value.length;
            end.focus();
            end.setSelectionRange(len, len);
        }
    }, [eye]);
    
    useEffect(() => {
        const end= document.querySelector('#psw2');
        if (end) {
            var len = end.value.length;
            end.focus();
            end.setSelectionRange(len, len);
        }
    }, [eyeConf]);

    useEffect(() => {
        if ((inputPass === inputPassConf) && !(inputPassConf === '') && (inputPassConf.length > 4)) {
            setPasConf(true);
        }
        else {
            setPasConf(false);
        };
    }, [inputPass, inputPassConf]);

    const inputPassHandler = (e) => {
        setInputPass(e.target.value);
    };

    const checkPassword = (e) => {
        var len= inputPass.length;
        
        if (e.key === 'Backspace') {
            len= len - 2;
        };

        if (len < 4) {
            setPasCheck(true);
            setRedPas(true);
        }
        else {
            setPasCheck(false);
            setRedPas(false);
        };
    };
    
    const inputPassConfHandler = (e) => {
        setInputPassConf(e.target.value);
        setRedPasConf(true);
    };

    const changeLine = () => {
        setLine(!line);
    };

    const showPas = () => {
        setEye(!eye);
    };
    
    const changeLineConf = () => {
        setLineConf(!lineConf);
    };

    const showPasConf = () => {
        setEyeConf(!eyeConf);
    };

    const check = (e) => {
        if ((inputPass === "") || (inputPassConf === "")) {
            e.preventDefault();
        };
        if (inputPass === "") {
            setRedPas(true);
        };
        if (inputPassConf === "") {
            setRedPasConf(true);
        };
    };
    
    const gotoLogin = async (e) => {
        e.preventDefault();

        await reset(inputPass, token, setPop);
    };

    return (
        <div className= "reset">
            <div className= "resetcontainer">
                <img src= {Pin} alt= "Pin Icon" />
                <form className= "resetcont" onSubmit= {gotoLogin}>
                    <div className= "connectrow r">
                        <div className= "connectlabel">{changelang ? "Password:" : "Κωδικός:"}</div>
                        <div className= "passwordform" style= {{outline: (line ? '1.5px solid white' : 'none'), 
                            borderWidth: (line ? '2px' : '1px'), borderColor: (redPas? 'red' : 'black')}} >
                                <input className= "connectform" type= {eye ? "password" : "text"} id= "psw"
                                    title= {inputPass} value= {inputPass} autoComplete= "off" autoFocus= "on"
                                    onChange= {inputPassHandler} onKeyDown= {checkPassword}
                                    onFocus= {changeLine} onBlur= {changeLine} />
                                <img src= {eye ? Show : Hide} alt= "Show Password Icon" onClick= {showPas} />
                        </div>
                        {pasCheck && <div id= "pswcheck">
                            {changelang ? 
                                "Password must contain at least 5 characters!"
                            :
                                "Ο κωδικός πρέπει να έχει τουλάχιστον 5 χαρακτήρες!"
                            }
                        </div>}
                    </div>
                    <div className= "connectrow r">
                        <div className= "connectlabel">
                            {changelang ? "Confirm password:" : "Επιβεβαίωση κωδικού:"}
                        </div>
                        <div className= "passwordform" style= {{outline: (lineConf ? '1.5px solid white' : 'none'), 
                            borderWidth: (lineConf ? '2px' : '1px'), 
                            borderColor: (redPasConf ? (pasConf ? 'green' : 'red') : 'black')}} >
                                <input className= "connectform" type= {eyeConf ? "password" : "text"} id= "psw2"
                                    title= {inputPassConf} value= {inputPassConf} autoComplete= "off"
                                    onChange= {inputPassConfHandler} 
                                    onFocus= {changeLineConf} onBlur= {changeLineConf} />
                                <img src= {eyeConf ? Show : Hide} alt= "Show Password Icon" onClick= {showPasConf} />
                        </div>
                    </div>
                    {((redPas && !pasCheck) || (redPasConf && (inputPassConf === ""))) && <div id= "errormessage">
                        {changelang ? "Fill in all fields!" : "Συμπληρώστε όλα τα πεδία!"}
                    </div>}
                    {error && <div id= "errormessage">
                        {changelang ? 
                            "Timed out! Request new link!" 
                        : 
                            "Έληξε ο χρόνος! Ζητήστε καινούριο σύνδεσμο!"
                        }
                    </div>}
                    <motion.input type= "submit" id= "resetpas" onClick= {check}
                        value= {changelang ? "Change password" : "Αλλαγή κωδικού"} disabled= {isLoading} 
                        whileHover= {{scale: 1.1}}  />
                </form>
            </div>
        </div>
    )
}

export default Reset;