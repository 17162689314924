import React, { createContext, useReducer } from 'react';

export const ExperimentContext = createContext()

export const experimentReducer = (state, action) => {
    switch (action.type) {
        case 'SET_EXPERIMENT':
            return {
                experiment: action.payload
            }
        case 'UPDATE_EXPERIMENT':
            return {
                experiment: {...state.experiment, ...action.payload}
            }
        default:
            return state
    }
}

export const ExperimentContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(experimentReducer, {
        experiment: null
    })

    return (
        <ExperimentContext.Provider value= {{...state, dispatch}}>
            {children}
        </ExperimentContext.Provider>
    )
}
