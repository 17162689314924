import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Sure from './SureC';
import Add from '../../Images/add.png';
import WhiteAdd from '../../Images/whiteadd.png';
import Delete from '../../Images/delete.png';
import Plus from '../../Images/plus.png';
import WhitePlus from '../../Images/whiteplus.png';
import x from '../../Images/x.png';
import { useExperimentContext } from '../../Hooks/useExperimentContext';
import DateISO from '../functions/DateISO';

const AddCards = ( {setPop, changelang} ) => {
    const { experiment, dispatch } = useExperimentContext();

    const [cards, setCards] = useState(experiment.cards);
    const [duplicate, setDuplicate] = useState(false);
    const [empty, setEmpty] = useState(false);
    const exist= (changelang ? ": Card already exists!" : ": Η κάρτα υπάρχει ήδη!");

    const [dispImportCards, setDispImportCards] = useState(false);
    const [inputCards, setInputCards] = useState("");
    const [redCards, setRedCards] = useState(false);

    const [over1, setOver1] = useState(false);
    const [over2, setOver2] = useState(false);

    const [dispAddaCard, setDispAddaCard] = useState(false);
    const [inputCard, setInputCard] = useState("");
    const [redCard, setRedCard] = useState(false);

    const [sure, setSure] = useState();

    useEffect(() => {
        if (experiment.datestart) {
            const now= DateISO();
            const d1= now >= experiment.datestart;
            const d2= now <= experiment.datestop;

            if (d1 && d2) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: {publish: true}});
            }
            else {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: {publish: false}});
            };
        };
    }, [dispatch, experiment.datestart, experiment.datestop]);

    document.body.onpointerdown= (e) => {
        if (e.target.getAttribute('data-inadd')) {
            return;
        };
        setInputCard("");
        setRedCard(false);
        setDispAddaCard(false);
    };

    const importCards = () => {
        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            setDispImportCards(true);
        };
    };

    const inputCardsHandler = (e) => {
        setInputCards(e.target.value);
        setRedCards(false);
        setDuplicate(false);
        setEmpty(false);
    };

    const checkall = (e) => {
        var importedCards= inputCards.replace(/\s+/g,' ').split(",");
        var importedCardsWS= [];
        importedCards.map((item, index) => {
            importedCards[index]= item.trim();
            return importedCardsWS[index]= item.replace(/\s/g,'');
        });
        if (importedCards.includes('')) {
            e.preventDefault();
            setRedCards(true);
            setEmpty(true);
        }
        else if ((new Set(importedCardsWS)).size !== importedCardsWS.length) {
            e.preventDefault();
            setRedCards(true);
            setDuplicate(true);
        }
        else {
            var newCards= [];
            importedCards.map((item, index) => {
                var newCard= {[`card-${index + 1}`]: { id: `card-${index + 1}`, name: item}};
                return newCards= {...newCards, ...newCard};
            });
            setCards(newCards);
        };
    };

    const addtheCards = async (e) => {
        e.preventDefault();
        
        const nexperiment = {cards: cards};

        const response = await fetch(`/api/dashboard/${experiment._id}`, {
            method: 'PATCH',
            body: JSON.stringify(nexperiment),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            dispatch({type: 'UPDATE_EXPERIMENT', payload: json});
            
            setInputCards("");
            setDispImportCards(false);

            setPop(8);
        }
        else {
            setCards({});
            
            setPop(15);
        };
    };

    const cancelImport = () => {
        setDispImportCards(false);
        setInputCards("");
        setRedCards(false);
        setDuplicate(false);
        setEmpty(false);
    };

    const deleteAll = () => {
        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            if (!experiment.publish) {
                setSure(1);
            };
        };
    };

    const addaCard = () => {
        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            setDispAddaCard(true);
        };
    };

    const inputCardHandler = (e) => {
        setInputCard(e.target.value);
        setRedCard(false);
    };
    
    const reset = () => {
        setInputCard(inputCard.replace(exist, ""));
    };

    const check = (e) => {
        var car= inputCard.replace(/\s+/g,' ').trim();

        if ((car === "")) {
            e.preventDefault();
            setInputCard(car);
            setRedCard(true);
        }
        else {
            if (redCard) {
                e.preventDefault();
                setInputCard(inputCard.replace(exist,'') + exist);
            }
            else {
                var checkValues= [];
                Object.values(cards).map((card) => {
                    return checkValues= [...checkValues, card.name];
                });
                if (checkValues.includes(car)) {
                    e.preventDefault();
                    setRedCard(true);
                    e.target.parentNode.childNodes[0].blur();
                    setInputCard(car + exist);
                };
            };
        };
    };

    const addtheCard = async (e) => {
        e.preventDefault();

        var car= inputCard.replace(/\s+/g,' ').trim();

        var l= Object.keys(cards).length + 1;

        const newCard= {[`card-${l}`]: {id: `card-${l}`, name: car}};
        const finalCards= {...cards, ...newCard};

        const nexperiment = {cards: finalCards};

        const response = await fetch(`/api/dashboard/${experiment._id}`, {
            method: 'PATCH',
            body: JSON.stringify(nexperiment),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            dispatch({type: 'UPDATE_EXPERIMENT', payload: json});

            setCards(finalCards);
            setInputCard("");
            setDispAddaCard(false);
            
            setPop(7);
        }
        else {
            setPop(15);
        };
    };

    const updatetheStates = async (e) => {
        e.preventDefault();

        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            var el1 = document.querySelector('input[name="randomizecards"]:checked');
            var el2 = document.querySelector('input[name="unsorted"]:checked');
            var rand= true;
            var unsort= false;
            if (el1 === null) {
                rand= false;
            }
            else {
                rand= true;
            };
            if (el2 === null) {
                unsort= false;
            }
            else {
                unsort= true;
            };

            const nexperiment = {randomizecards: rand, unsorted: unsort};

            const response = await fetch(`/api/dashboard/${experiment._id}`, {
                method: 'PATCH',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();

            if (response.ok) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: json});
                setPop(9);
            }
            else {
                setPop(15);
            };
        };
    };

    const deleteCard = async (index) => {
        if (!experiment.publish) {
            const dcards= {...cards};

            delete dcards[`card-${index + 1}`];
            
            var finalCards= {};
            Object.values(dcards).map((card, index) => {
                return finalCards= {...finalCards, 
                    [`card-${index + 1}`] : { id: `card-${index + 1}`, name: card.name}}
            });
            
            const nexperiment = {cards: finalCards};

            const response = await fetch(`/api/dashboard/${experiment._id}`, {
                method: 'PATCH',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();

            if (response.ok) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: json});

                setCards(finalCards);

                setPop(4);
            }
            else {
                setPop(15);
            };
        };
    };

    return (
        <div className= "addcards" id= "containerdscrollbar">
            <div className= "cardsside">
                <div className= "cardsheader">
                    <button className= "cardsbutton" id= "importcards" onClick= {importCards}
                        onMouseOver= {() => setOver1(true)} onMouseOut= {() => setOver1(false)} 
                        disabled= {experiment.publish} >
                            <span>{changelang ? "Upload" : "Ανέβασμα"}</span>
                            <img src= {(over1 && !experiment.publish)? Add : WhiteAdd} alt= "Add Multiple Icon" />
                    </button>
                    <div id= "totalcards">
                        #
                        <span id= "totalcardstext" >
                            No:&nbsp;
                        </span>
                        {Object.keys(cards).length}
                        <div className= "iconhover" onClick= {deleteAll} data-isdisabled= {experiment.publish} 
                            title= {changelang ? "Delete all" : "Διαγραφή όλων"}>
                                <img src= {Delete} alt= "Delete Icon" />
                        </div>
                    </div>
                    <button className= "cardsbutton" id= "addcards" onClick= {addaCard} data-inadd= {true}
                        onMouseOver= {() => setOver2(true)} onMouseOut= {() => setOver2(false)} 
                        disabled= {experiment.publish} >
                            <span>{changelang ? "Add" : "Προσθήκη"}</span>
                            <img src= {(over2 && !experiment.publish)? Plus : WhitePlus} alt= "Add Icon" />
                    </button>
                </div>
                <div className= "cardsbody" id= "containerdscrollbar">
                    {dispImportCards && <form className= "importcards" onSubmit= {addtheCards}>
                        <div className= "importcardscontainer">
                            <div id= "importcardsinfo">
                                {changelang ? 
                                    "Add the card names separated by" 
                                :
                                    "Προσθέστε τα ονόματα καρτών διαχωρισμένα με"
                                }&nbsp;
                                <span style= {{fontWeight: "bold"}}>
                                    {changelang ? "commas" : "κόμματα"}
                                </span>.
                                <br/>
                                <span style= {{fontWeight: "bold"}}>
                                    {changelang ? "ATTENTION:" : "ΠΡΟΣΟΧΗ:"}&nbsp;
                                </span>
                                {changelang ? 
                                    "These cards will replace the existing ones!" 
                                :
                                    "Οι κάρτες αυτές θα αντικαταστήσουν τις υπάρχουσες!"
                                }
                            </div>
                            <textarea className= "taskstextarea" id= "containerdscrollbar" 
                                placeholder= {changelang ? 
                                    "π.χ.: Card1, Card2, Card3, etc..." 
                                : 
                                    "π.χ.: Κάρτα1, Κάρτα2, Κάρτα3, κλπ..."
                                }
                                value= {inputCards} autoComplete= "off" autoFocus= "on" onChange= {inputCardsHandler} 
                                style= {{color: (redCards? 'red' : 'black'), borderColor: (redCards? 'red' : 'black')}} >
                            </textarea>
                            {duplicate && <div id= "duplicated">
                                {changelang ? 
                                    "There are duplicate card names!" 
                                :
                                    "Υπάρχουν διπλότυπα ονόματα καρτών!"
                                }
                            </div>}
                            {empty && <div id= "duplicated">
                                {changelang ? 
                                    "There are empty cards!" 
                                :
                                    "Υπάρχουν κενές κάρτες!"
                                }
                            </div>}
                            <div className= "importcardsbuttons">
                                <input type= "submit" className= "importcardsbutton" id= "savecardsbutton" 
                                    value= {changelang ? "Save" : "Αποθήκευση"} onClick= {checkall} />
                                <button type= "button" className= "importcardsbutton" id= "cancelimport" 
                                    onClick= {cancelImport} >
                                        {changelang ? "Cancel" : "Ακύρωση"}
                                </button>
                            </div>
                        </div>
                    </form>}
                    {dispAddaCard && <form className= "addacard" data-inadd= {true} onSubmit= {addtheCard}>
                        <input className= "expform" type= "text" title= {inputCard} value= {inputCard} 
                            autoComplete= "off" autoFocus= "on" onChange= {inputCardHandler} onClick= {reset}
                            style= {{color: (redCard? 'red' : 'black'), borderColor: (redCard? 'red' : 'black')}} data-inadd= {true} />
                        <input type= "submit" className= "savecardbutton" value= {changelang ? "Save" : "Αποθήκευση"} 
                            onClick= {check} data-inadd= {true} />
                    </form>}
                    {Object.values(cards).map((card, index) => {
                        return (
                            <div key= {card.id} className= "addedcard">
                                <span>{card.name}</span>
                                <div id= "deletecardicon" onClick= {() => deleteCard(index)} 
                                    title= {changelang ? "Delete card" : "Διαγραφή κάρτας"} 
                                    data-isdisabled= {experiment.publish} >
                                        <img src= {x} alt= "Delete Card Icon" />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>       
            <div className= "settingsside">
                <form className= "settingscontainer" onSubmit= {updatetheStates}>
                    <div className= "settingscheckbox">
                        <input type="checkbox" id="randomizecards" name="randomizecards" value="randomizecards" 
                                defaultChecked= {experiment.randomizecards} disabled= {experiment.publish} />
                        <label htmlFor= "randomizecards">
                            {changelang ?
                                "Display cards in random order." 
                            :
                                "Εμφάνιση καρτών σε τυχαία σειρά."
                            }
                        </label>
                    </div>
                    <div className= "settingscheckbox">
                        <input type="checkbox" id="unsorted" name="unsorted" value="unsorted" 
                                defaultChecked= {experiment.unsorted} disabled= {experiment.publish} />
                        <label htmlFor= "unsorted">
                            {changelang ?
                                "Allow unsorted cards." 
                            :
                                "Να επιτρέπονται μη ταξινομημένες κάρτες."
                            }
                        </label>
                    </div>
                    <input type= "submit" className= "savecardbutton" value= {changelang ? "Update" : "Ενημέρωση"} 
                        disabled= {experiment.publish} />
                </form>
            </div>
            <AnimatePresence>
                {sure && <Sure setCards= {setCards} sure= {sure} setSure= {setSure} setPop= {setPop} 
                    changelang= {changelang} />}
            </AnimatePresence>
        </div>
    )
}

export default AddCards;