import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './HeaderH';
import Board from './Board';
import Notes from './Notes';
import Forgot from './Forgot';
import Reset from './Reset';
import NotToken from '../Errors/NotToken';
import PopUp from './PopUpH';
import ChangeLanguageH from './ChangeLanguageH';
import './Home.css';

const Home = () => {
    const [cookie, setCookie] = useState(false);

    const [pop, setPop] = useState(0);
    
    const [changelang, setChangelang] = useState(false);

    useEffect(() => {
        window.addEventListener('load', ChangeLanguageH());
        const fetchUser = async () => {
            const response = await fetch('/get-cookie');
            const json = await response.json();

            if (response.ok) {
                setCookie(true);
                // window.location.assign('/dashboard');
                // dispatchA({type: "LOGIN"})

            }
            else {
                if (response.status === 401) {
                    setCookie(false);
                };
                // setCookie(null);
                console.log(json)
                // setPop(14);
            };
        };

        fetchUser();
    }, []);

    return (
        <div className= "home">
            <Header changelang= {changelang} setChangelang= {setChangelang} />
            <Routes>
                <Route path= "/" element= {<Board changelang= {changelang} />} />
                <Route path= "/about" element= {<Notes changelang= {changelang} />} />
                <Route path= "/features" element= {<Notes changelang= {changelang} />} />
                <Route path= "/login" element= {<Notes cookie= {cookie} changelang= {changelang} />} />
                <Route path= "/signup" element= {<Notes cookie= {cookie} setPop= {setPop} changelang= {changelang} />} />
                <Route path= "/forgotpassword" element= {<Forgot cookie= {cookie} setPop= {setPop} 
                    changelang= {changelang} />} />
                <Route path= "/resetpassword/:token" element= {<Reset setPop= {setPop} changelang= {changelang} />} />
                <Route path= "/expired" element= {<NotToken changelang= {changelang} />} />
                <Route path="*" element= {<Navigate to="/" />} />
            </Routes>
            <AnimatePresence>
                {pop && <PopUp pop= {pop} setPop= {setPop} changelang= {changelang} />}
            </AnimatePresence>
        </div>
    )
}

export default Home;