export const useLogout = () => {

    const logout = async () => {
        
        const response = await fetch('/logout');
        const json = await response.json();
        
        if (response.ok) {
            // remove user from storage
            // localStorage.removeItem('user');
            
            // dispatchA({type: 'LOGOUT'});

            window.location.assign('/');
        }
        else {
            // setPop(15);
            console.log(json)
        };
    }
    return { logout };
}