import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Show from '../../Images/show.png';
import Hide from '../../Images/hide.png';
import { useUserContext } from '../../Hooks/useUserContext';

const Settings = ( {setSure, setPop, changelang} ) => {
    const { user, dispatchU } = useUserContext();
    // const [user, setUser] = useState(null);

    const [inputUser, setInputUser] = useState("");
    const [inputMail, setInputMail] = useState("");
    const [dispPas, setDispPas] = useState(false);

    const [inputPass, setInputPass] = useState("");
    const [inputPassNew, setInputPassNew] = useState("");
    const [inputPassConf, setInputPassConf] = useState("");
    
    const [line, setLine] = useState(false);
    const [lineNew, setLineNew] = useState(false);
    const [lineConf, setLineConf] = useState(false);
    const [eye, setEye] = useState(true);
    const [eyeNew, setEyeNew] = useState(true);
    const [eyeConf, setEyeConf] = useState(true);
    
    // const [pas, setPas] = useState(false);
    const [pasCheck, setPasCheck] = useState(false);
    const [pasConf, setPasConf] = useState(false);

    const [redUser, setRedUser] = useState(false);
    const [redPas, setRedPas] = useState(false);
    const [redPasNew, setRedPasNew] = useState(false);
    const [redPasConf, setRedPasConf] = useState(false);

    if (!user) {
        window.location.assign('/dashboard');
    };

    useEffect(() => {
        if (user) {
            setInputUser(user.name);
            setInputMail(user.email);
        };
    }, [user]);

    useEffect(() => {
        const end= document.querySelector('#psw');
        if (end) {
            var len = end.value.length;
            end.focus();
            end.setSelectionRange(len, len);
        }
    }, [eye]);
    
    useEffect(() => {
        const end= document.querySelector('#pswn');
        if (end) {
            var len = end.value.length;
            end.focus();
            end.setSelectionRange(len, len);
        }
    }, [eyeNew]);
    
    useEffect(() => {
        const end= document.querySelector('#psw2');
        if (end) {
            var len = end.value.length;
            end.focus();
            end.setSelectionRange(len, len);
        }
    }, [eyeConf]);
    
    useEffect(() => {
        if ((inputPassNew === inputPassConf) && !(inputPassConf === '') && (inputPassConf.length > 4)) {
            setPasConf(true);
        }
        else {
            setPasConf(false);
        };
    }, [inputPassNew, inputPassConf]);

    const inputUserHandler = (e) => {
        setInputUser(e.target.value);
        setRedUser(false);
    };
    
    const inputPassHandler = (e) => {
        setInputPass(e.target.value);
        setRedPas(false);

        // if (user.password === e.target.value) {
        //     setPas(true);
        // }
        // else {
        //     setPas(false);
        // };
    };
    
    const inputPassNewHandler = (e) => {
        setInputPassNew(e.target.value);
    };

    const checkPassword = (e) => {
        var len= inputPassNew.length;
        
        if (e.key === 'Backspace') {
            len= len - 2;
        };

        if (len < 4) {
            setPasCheck(true);
            setRedPasNew(true);
        }
        else {
            setPasCheck(false);
            setRedPasNew(false);
        };
    };
    
    const inputPassConfHandler = (e) => {
        setInputPassConf(e.target.value);
        setRedPasConf(true);
    };

    const changeLine = () => {
        setLine(!line);
    };

    const showPas = () => {
        setEye(!eye);
    };
    
    const changeLineNew = () => {
        setLineNew(!lineNew);
    };

    const showPasNew = () => {
        setEyeNew(!eyeNew);
    };
    
    const changeLineConf = () => {
        setLineConf(!lineConf);
    };

    const showPasConf = () => {
        setEyeConf(!eyeConf);
    };

    const changePasDisp = () => {
        setDispPas(!dispPas);
    };

    const check = (e) => {
        if (inputUser === "") {
            e.preventDefault();
            setRedUser(true);
        };
    };
    
    const checkpas = (e) => {
        if (inputPass === "") {
            e.preventDefault();
            setRedPas(true);
        };
        if (inputPassNew === "") {
            e.preventDefault();
            setRedPasNew(true);
        };
        if (inputPassConf === "") {
            e.preventDefault();
            setRedPasConf(true);
        };
        if ((redPasNew === true) || (pasConf === false)) {
            e.preventDefault();
        };
    };

    const changeFormElements = async (e) => {
        e.preventDefault();
        // setUser({...user, name: inputUser, mail: inputMail});

        const nuser = {name: inputUser};
    
        const response = await fetch('/api/dashboard/settings', {
            method: 'PATCH',
            body: JSON.stringify(nuser),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            dispatchU({type: 'UPDATE_USER', payload: json});

            setPop(2);
        }
        else {
            setPop(15);
        };
    };
    
    const changePassword = async (e) => {
        e.preventDefault();
        // setUser({...user, password: inputPassNew});
        
        const nuser = {email: inputMail, password: inputPass, pasNew: inputPassNew};
    
        const response = await fetch('/api/dashboard/settings', {
            method: 'PATCH',
            body: JSON.stringify(nuser),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            dispatchU({type: 'UPDATE_USER', payload: json});
            
            setInputPass("");
            setInputPassNew("");
            setInputPassConf("");

            setRedPasConf(false);

            setPop(3);
        }
        else {
            if (response.status === 400) {
                setRedPas(true);
            }
            else {
                setPop(15);
            };
        };
    };

    const deleteaccount = () => {
        setSure(4);
    };

    return (
        <div className= "settings">
            {user && <form className= "connectformside d" id= "containerdscrollbar" onSubmit= {changeFormElements}>
                <div className= "connectforms">
                    <div className= "connectrow s d">
                        <div className= "connectlabel d">{changelang ? "Username:" : "Όνομα χρήστη:"}</div>
                        <input className= "connectform d" type= "text" id= "username"
                            title= {inputUser} value= {inputUser} autoComplete= "off"
                            onChange= {inputUserHandler} style= {{borderColor: (redUser? 'red' : 'black')}} />
                    </div>
                    <div className= "connectrow s d">
                        <div className= "connectlabel d">Email:</div>
                        <input className= "connectform d" type= "email" placeholder= "example@email.com" id= "mail"
                            title= {inputMail} value= {inputMail} disabled= {true} />
                    </div>
                    {redUser && <div id= "errormessage">
                        {changelang ? "Fill in the field!" : "Συμπληρώστε το πεδίο!"}
                    </div>}
                    <motion.input type= "submit" className= "connectbuttond" id= "changeset" 
                        value= {changelang ? "Save" : "Αποθήκευση" } whileHover= {{scale: 1.1}} onClick= {check} />
                </div>
                <motion.button type= "button" className= "connectbuttond" id= "changepasdisp" whileHover= {{scale: 1.1}} 
                    onClick= {changePasDisp} >
                        {changelang ? "Change password:" : "Αλλαγή κωδικού"}
                </motion.button>
                <motion.button type= "button" className= "connectbuttond" id= "delaccount" whileHover= {{scale: 1.1}} 
                    onClick= {deleteaccount} >
                        {changelang ? "Delete Account" : "Διαγραφή Λογαριασμού"}
                </motion.button>
            </form>}
            {user && dispPas && <form className= "connectformside p" id= "containerdscrollbar" onSubmit= {changePassword}>
                <div className= "connectforms d" id= "containerdscrollbar">
                    <div className= "connectrow s d">
                        <div className= "connectlabel d">{changelang ? "Password:" : "Κωδικός:"}</div>
                        <div className= "passwordform d" style= {{outline: (line ? '1.5px solid white' : 'none'), 
                            borderWidth: (line ? '2px' : '1px'), 
                            borderColor: (redPas ? 'red' : 'black')}} >
                                <input className= "connectform" type= {eye ? "password" : "text"} id= "psw"
                                    title= {inputPass} value= {inputPass} autoComplete= "off" autoFocus= "on"
                                    onChange= {inputPassHandler}
                                    onFocus= {changeLine} onBlur= {changeLine} />
                                <img src= {eye ? Show : Hide} alt= "Show Password Icon" onClick= {showPas} />
                        </div>
                        {(redPas && pasConf) && <div id= "pascheck" style= {{fontSize: '1em'}}>
                            {changelang ? "Wrong password!" : "Λάθος κωδικός!"}
                        </div>}
                    </div>
                    <div className= "connectrow s d">
                        <div className= "connectlabel d">{changelang ? "New password:" : "Νέος κωδικός:"}</div>
                        <div className= "passwordform d" style= {{outline: (lineNew ? '1.5px solid white' : 'none'), 
                            borderWidth: (lineNew ? '2px' : '1px'), borderColor: (redPasNew? 'red' : 'black')}} >
                                <input className= "connectform" type= {eyeNew ? "password" : "text"} id= "pswn"
                                    title= {inputPassNew} value= {inputPassNew} autoComplete= "off"
                                    onChange= {inputPassNewHandler} onKeyDown= {checkPassword}
                                    onFocus= {changeLineNew} onBlur= {changeLineNew} />
                                <img src= {eyeConf ? Show : Hide} alt= "Show Password Icon" onClick= {showPasNew} />
                        </div>
                        {pasCheck && <div id= "pascheck">
                            {changelang ? 
                                "Password must contain at least 5 characters!"
                            :
                                "Ο κωδικός πρέπει να έχει τουλάχιστον 5 χαρακτήρες!"
                            }
                        </div>}
                    </div>
                    <div className= "connectrow s d">
                        <div className= "connectlabel d">
                            {changelang ? "Confirm password:" : "Επιβεβαίωση κωδικού:"}
                        </div>
                        <div className= "passwordform d" style= {{outline: (lineConf ? '1.5px solid white' : 'none'), 
                            borderWidth: (lineConf ? '2px' : '1px'), 
                            borderColor: (redPasConf ? (pasConf ? 'green' : 'red') : 'black')}} >
                                <input className= "connectform" type= {eyeConf ? "password" : "text"} id= "psw2"
                                    title= {inputPassConf} value= {inputPassConf} autoComplete= "off"
                                    onChange= {inputPassConfHandler} 
                                    onFocus= {changeLineConf} onBlur= {changeLineConf} />
                                <img src= {eyeConf ? Show : Hide} alt= "Show Password Icon" onClick= {showPasConf} />
                        </div>
                    </div>
                    {((redPas && (inputPass === "")) || (redPasNew && !pasCheck) || (redPasConf && (inputPassConf === ""))) && <div id= "errormessage">
                        {changelang ? "Fill in all fields!" : "Συμπληρώστε όλα τα πεδία!"}
                    </div>}
                    <motion.input type= "submit" className= "connectbuttond" id= "changepas" 
                        value= {changelang ? "Save" : "Αποθήκευση"} whileHover= {{scale: 1.1}} onClick= {checkpas} />
                </div>
            </form>}
        </div>
    )
}

export default Settings;