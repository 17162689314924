import React from 'react';
import { motion } from 'framer-motion';
import DateISO from '../functions/DateISO';

const sureVariants = {
    initial: {
        scale: 0
    },
    animate: {
        scale: 1,
        transition: { duration: 0.3 }
    },
    exit: {
        scale: 0, 
        transition: { duration: 0.2 }
    }
}

const Sure = ( {sure, setSure, categoryinfo, setPop, changelang, cards, cardsInCategory, categories, categoriesOrder,
    setCards, setCardsInCategory, setCategories, setCategoriesOrder,
    setThanks, exp, comments} ) => {
        // const [res, setRes] = useState(results);
        // console.log(res);
        
        const renderSure = () => {
            switch(sure) {
                default: return;
                case 1: return (changelang ? "submit this?" : "υποβληθεί;");
                case 2: return(
                    <div>
                        {changelang ? "submit this?" : "υποβληθεί;"}
                        <br />
                        {changelang ? "There are" : "Υπάρχουν"} 
                        <u> {changelang ? "unsorted cards" : "μη ταξινομημένες κάρτες"}</u>
                        <span style= {{color: 'red'}}>!!!</span>
                    </div>
                )
                case 3: return (changelang ? "delete this category?" : "διαγράψετε αυτή την κατηγορία;");
                case 4: return (changelang ? "delete all category cards?" : "διαγράψετε όλες τις κάρτες της κατηγορίας;");
            };
        };

        const no = () => {
            setSure(null);
        };

        const yes = async () => {
            var newCards= {};

            if (sure === 1 || sure === 2) {
                const now= DateISO();
                const d1= now >= exp.datestart;
                const d2= now <= exp.datestop;

                if ((exp.publish === true) || (d1 && d2)) {
                    const datas= [];
                    categoriesOrder.order.map((categoryId) => {
                        const category= categories[categoryId];
                        category.cardId.map(cardId => {
                            cardId= cardsInCategory[cardId];
                            const data1= {card_id: +cardId.id.replace( /^\D+/g, ''), card_name: cardId.name, 
                                cat_id: +category.id.replace( /^\D+/g, ''), cat_name: category.name};
                            datas.push(data1);
                            return (cardId);
                        });
                        return (categoryId);
                    });
                    Object.values(cards).map((card) => {
                        const data1= {card_id: +card.id.replace( /^\D+/g, ''), card_name: card.name, 
                            cat_id: "", cat_name: ""};
                        datas.push(data1);
                        return (card);
                    });
                    // const result= {res_id: res.length + 1, data: datas, comments: comments, exp_id: theid};
                    // setRes([...res, result]);
                    
                    const nresult = {data: datas, comments: comments, exp_id: exp._id};

                    const response = await fetch('/api/sort', {
                        method: 'POST',
                        body: JSON.stringify(nresult),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const json = await response.json();
                    
                    if (response.ok) {
                        window.localStorage.removeItem(`${exp._id}`);
                        setThanks(true);
                    }
                    else {
                        console.log(json);
                        setPop(8);
                    };
                }
                else {
                    window.location.pathname= "/notfound";
                };
            }
            else if (sure === 3) {
                const category= categoryinfo;
    
                // var newCategoriesOrder= categoriesOrder.order.filter(function(value) { 
                //     return !(value === category.id);
                // });
                // setCategoriesOrder({...categoriesOrder, order: newCategoriesOrder});
    
                const newCategories= {...categories};
                delete newCategories[category.id];
                // setCategories({...newCategories});

                // const theId= +category.id.match(/\d+/)[0];
                const theLength= Object.values(categoriesOrder)[0].length;
                var theArray= [];
                var newIdsArray= {};

                for (let i= 0; i < theLength-1; i++) {
                    theArray= [...theArray, Object.values(categoriesOrder)[0][i]];

                    const newCategoryId= Object.values(categoriesOrder)[0][i];
                    const newCategory= { [newCategoryId] : 
                        { id: newCategoryId, name: Object.values(newCategories)[i].name, 
                            cardId: Object.values(newCategories)[i].cardId, 
                            minimize: Object.values(newCategories)[i].minimize }};
                    newIdsArray= {...newIdsArray, ...newCategory};
                };
                
                setCategoriesOrder({...categoriesOrder, order: theArray});
                setCategories({...newIdsArray});
    
                const theCards= Array.from(category.cardId);
                const newCardsInCategory= {...cardsInCategory};
                
                theCards.forEach( (e) => {
                    delete newCardsInCategory[e];
                    
                    newCards= {...newCards, [e]: cardsInCategory[e]};
                });
    
                setCardsInCategory({...newCardsInCategory});
                setCards({...cards, ...newCards});
    
                setPop(6);
            }
            else if (sure === 4) {
                const category= categoryinfo;

                const newCardId= Array.from(category.cardId);
                const theCards= Array.from(category.cardId);
                        
                newCardId.splice(0, newCardId.length);
                const newCategory= {...category, cardId: newCardId};
                setCategories({...categories, [category.id]: newCategory});
                
                const newCardsInCategory= {...cardsInCategory};
            
                theCards.forEach( (e) => {
                    delete newCardsInCategory[e];
                    
                    newCards= {...newCards, [e]: cardsInCategory[e]};
                });

                setCardsInCategory({...newCardsInCategory});
                setCards({...cards, ...newCards});

                setPop(7);
            };
            setSure(null);
        };

        return(
            <div className= "sure">
                <motion.div className= "surecontainer" {...sureVariants}>
                    <div className= "suremessage">
                        {changelang ? "Are you sure you want to" : "Θέλετε σίγουρα να"}
                        <br />
                        {renderSure()}
                    </div>
                    <div className= "surebuttoncontainer">
                        <button className= "surebutton" id= "sno" onClick= {no}>
                            {changelang ? "Νo" : "Οχι"}
                        </button>
                        <button className= "surebutton" id= "syes" type= "submit" onClick= {yes}>
                            {changelang ? "YES" : "ΝΑΙ"}
                        </button>
                    </div>
                </motion.div>
            </div>
        );
}

export default Sure;