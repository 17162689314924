import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import Delete from '../../Images/delete.png';
import Export from '../../Images/export.png';
import { useExperimentContext } from '../../Hooks/useExperimentContext';
import { useResultsContext } from '../../Hooks/useResultsContext';
import { useExperimentsContext } from '../../Hooks/useExperimentsContext';
import DateISO from '../functions/DateISO';

const Experiments = ( {setSure, setTheexp, setPop, changelang} ) => {
    const { dispatch } = useExperimentContext();
    const { dispatchR } = useResultsContext();

    // console.log(data["user1"].experiments);
    // const [experiments, setExperiments] = useState(null);
    const { experiments, dispatchX } = useExperimentsContext();

    useEffect(() => {
        const fetchExperiments = async () => {
            const response = await fetch('/api/dashboard');
            const json = await response.json();

            if (response.ok) {
                // setExperiments(json);
                dispatchX({type: 'SET_EXPERIMENTS', payload: json});
            }
            else {
                if (response.status === 401) {
                    setPop(16);
                    // window.location.assign('/login');
                }
                else {
                    setPop(14);
                };
                console.log(json);
            };
        };

        fetchExperiments();
        
        dispatch({type: 'SET_EXPERIMENT', payload: null});
        dispatchR({type: 'SET_RESULT', payload: null});
    }, [dispatch, dispatchX, dispatchR])

    const deleteexp = (exp) => {
        const now= DateISO();
        const d1= now >= exp.datestart;
        const d2= now <= exp.datestop;
        // console.log(now)
        // console.log(experiment.datestart)
        // console.log(experiment.datestop)
        // console.log(experiment.publish)
        // console.log(now >= experiment.datestart)
        // console.log(now <= experiment.datestop)
        if ((exp.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            setSure(2);
            setTheexp(exp._id);
        };
    };
    
    return (
        <ul className= "experiments" id= "containerdscrollbar">
            <li className= "exptable title">
                <div>
                    {changelang ? "Experiment name" : "Όνομα πειράματος"}
                </div>
                <div>
                    {changelang ? "Created at:" : "Δημιουργήθηκε:"}
                </div>
                <div>
                    {changelang ? "Modified at:" : "Τροποποιήθηκε:"}
                </div>
                <div>
                    {changelang ? "Delete" : "Διαγραφή"}
                </div>
                <div>
                    {changelang ? "Export" : "Εξαγωγή"}
                </div>
            </li>
            {experiments && experiments.map(exp => {
                var fulldate1= new Date(exp.createdAt);
                var fulldate2= new Date(exp.updatedAt);
                return (
                    <li className= "exptable" key= {exp._id}>
                        <div id= "expname" title= {exp.name}>
                            <Link to= {`/dashboard/${exp._id}`}>
                                <div id= "nametext">
                                    {exp.name}
                                </div>
                            </Link>
                        </div>
                        <div id= "expdatecreated">
                            {fulldate1.toLocaleString("en-GB")}
                        </div>
                        <div id= "expdatemod">
                            {fulldate2.toLocaleString("en-GB")}
                        </div>
                        <div id= "expdel">
                            <div className= "iconhov" onClick= {() => deleteexp(exp)}>
                                <img src= {Delete} alt= "Delete Icon" />
                            </div>
                        </div>
                        <div id= "expres">
                            <Link to= {`/dashboard/${exp._id}/results`}>
                                <div className= "iconhov">
                                    <img src= {Export} alt= "Export Icon" />
                                </div>
                            </Link>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default Experiments;