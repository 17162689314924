import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Pin from '../../Images/pin.png';
import { useForgot } from '../../Hooks/useForgot';

const Forgot = ( {cookie, setPop, changelang} ) => {
    const { forgot, isLoading, error } = useForgot();

    const [inputMail, setInputMail] = useState("");
    const [redMail, setRedMail] = useState(false);

    if (cookie === true) {
        return <Navigate to="/dashboard" replace />;
    };

    const inputMailHandler = (e) => {
        setInputMail(e.target.value);
        setRedMail(false);
    };

    const send = (e) => {
        if (inputMail === "") {
            e.preventDefault();
            setRedMail(true);
        };
    };

    const sendEmail = async (e) => {
        e.preventDefault();

        await forgot(inputMail, setPop);
        // setTimeout(function() {
        //     window.location.pathname= "/login";
        // }, 1300);
    };

    return (
        <div className= "forgot">
            <div className= "forgotcontainer">
                <img src= {Pin} alt= "Pin Icon" />
                <form className= "forgotcont" onSubmit= {sendEmail} noValidate>
                    <div className= "connectrow f">
                        <div className= "connectlabel">Email:</div>
                        <input className= "connectform" type= "email" placeholder= "example@email.com" id= "mail"
                            title= {inputMail} value= {inputMail} autoComplete= "on" autoFocus= "on"
                            onChange= {inputMailHandler} style= {{borderColor: (redMail? 'red' : 'black')}} />
                    </div>
                    {(error || redMail) && <div id= "errormessage">
                        {redMail ? 
                        (changelang ? "Fill in the field!" : "Συμπληρώστε το πεδίο!")
                        : (error.includes('έγκυρο') ? 
                            (changelang ? "Not valid email!" : "Μη έγκυρο email!")
                            :
                            (error.includes('υπάρχει') ?
                                (changelang ? "Email does not exist!" : "Δεν υπάρχει αυτό το email!")
                            : 
                                (changelang ? "Something went wrong!" : "Κάτι πήγε λάθος!")
                            )
                        )}
                    </div>}
                    <motion.input type= "submit" id= "send" value= {changelang ? "Send email" : "Αποστολή email"}  
                        disabled= {isLoading} whileHover= {{scale: 1.1}} onClick= {send} />
                    <div id= "tolink">
                        <Link to= "/login">
                            {changelang ? "LogIn!" : "Σύνδεση!"}
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Forgot;