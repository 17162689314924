import React from 'react';
import { motion } from 'framer-motion';
import Pin from '../../Images/pin.png';
import './Errors.css';

const buttonVariants= {
    animate: {
        scale: 1.2
    },
    transition: { duration: 0.5, yoyo: Infinity }
}

const NotExperiment = ( {changelang} ) => {
    const returnForgot = () => {
        window.location.assign('/forgotpassword');
    };

    return (
        <div className= "reset">
            <div className= "resetcontainer">
                <img src= {Pin} alt= "Pin Icon" />
                    <div className= "nottoken">
                        <p>
                            {changelang ? 
                                "Timed out! Request new link!" 
                            : 
                                "Έληξε ο χρόνος! Ζητήστε καινούριο σύνδεσμο!"
                            }
                        </p>
                        <motion.button id= "toforgot" onClick= {returnForgot} {...buttonVariants}>
                            Link
                        </motion.button>
                    </div>
            </div>
        </div>
    )
}

export default NotExperiment;