
const DateISO = () => {
    const now= new Date();
    // const date= now.toLocaleDateString("sv-SE");
    // const time= now.toLocaleTimeString("sv-SE");
    // const dateTime= date + 'T' + time + 'Z';
    // console.log(now.toISOString())
    // console.log(date)
    // console.log(time)
    // console.log(dateTime)
    return now.toISOString();
}

export default DateISO;