import React from 'react';
import { motion } from 'framer-motion';
import { useExperimentContext } from '../../Hooks/useExperimentContext';

const sureVariants = {
    initial: {
        scale: 0
    },
    animate: {
        scale: 1,
        transition: { duration: 0.3 }
    },
    exit: {
        scale: 0, 
        transition: { duration: 0.2 }
    }
}

const Sure = ( {setCards, setCategories, sure, setSure, setPop, changelang} ) => {
    const { experiment, dispatch } = useExperimentContext();

    const renderSure = () => {
        switch(sure) {
            default: return;
            case 1: return (changelang ? "delete all the cards?" : "διαγράψετε όλες τις κάρτες;");
            case 2: return (changelang ? "delete all the categories?" : "διαγράψετε όλες τις κατηγορίες;");
        };
    };

    const no = () => {
        setSure(null);
    };

    const yes = async () => {
        if (sure === 1) {
            const nexperiment = {cards: {}};

            const response = await fetch(`/api/dashboard/${experiment._id}`, {
                method: 'PATCH',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();

            if (response.ok) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: json});
                setCards({});

                setPop(10);
                setSure(null);
            }
            else {
                setPop(15);
            };
        }
        else if (sure === 2) {
            const nexperiment = {categories: {}};

            const response = await fetch(`/api/dashboard/${experiment._id}`, {
                method: 'PATCH',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();

            if (response.ok) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: json});
                setCategories({});

                setPop(10);
                setSure(null);
            }
            else {
                setPop(15);
            };
        };
    };

    return(
        <div className= "sure">
            <motion.div className= "surecontainer" {...sureVariants}>
                <div className= "suremessage">
                    {changelang ? "Are you sure you want to" : "Θέλετε σίγουρα να"}
                    <br />
                    {renderSure()}
                </div>
                <div className= "surebuttoncontainer">
                    <button className= "surebutton" id= "sno" onClick= {no}>
                        {changelang ? "No" : "Οχι"}
                    </button>
                    <button className= "surebutton" id= "syes" type= "submit" onClick= {yes}>
                        {changelang ? "YES" : "ΝΑΙ"}
                    </button>
                </div>
            </motion.div>
        </div>
    );
}

export default Sure;