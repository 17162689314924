import React from 'react';
import { motion } from 'framer-motion';

const thanksVariants = {
    initial: {
        scale: 0
    },
    animate: {
        scale: 1,
        transition: { duration: 0.3 }
    }
}

const Thankyou = ( {changelang, bye} ) => {
    const redotest = () => {
        window.location.reload();
    };

    const closewindow = () => {
       window.close();
    };

        return(
            <div className= "thankyou">
                <motion.div className= "thankyoucontainer" {...thanksVariants}>
                    <p id= "thanks">
                        {changelang ?
                                "THANK YOU!"
                            : 
                                "Ευχαριστούμε!"
                        }
                    </p>
                    <div id= "note">
                        {(bye[0]) ?
                            <p className= "thankyoubuttons">
                                <motion.button className= "thankyoubutton r" onClick= {redotest}
                                    whileHover= {{ scale: 1.2 }} >
                                        {changelang ? "Redo sort" : "Επανάληψη"}
                                </motion.button>
                                <motion.button className= "thankyoubutton c" onClick= {closewindow}
                                    whileHover= {{ scale: 1.2 }} >
                                        {changelang ? "Close" : "Κλείσιμο"}
                                </motion.button>
                            </p>
                        :
                            <div>
                                {changelang ?
                                    "Go to the following website to complete the test:"
                                : 
                                    "Μεταβείτε στον παρακάτω ιστότοπο για να ολοκληρώσετε τη διαδικασία:"
                                }
                                <br />
                                <motion.a href= {bye[1]} target= "_blank" rel="noopener noreferrer"
                                    style= {{ width: '100%', padding: '5px 0 0 5px', display: 'block', fontSize: '0.9em', 
                                        fontWeight: 'bold', overflowX: 'hidden', whiteSpace: 'nowrap', 
                                        textOverflow: 'ellipsis' }} 
                                    whileHover= {{ opacity: '0.7' }} onClick= {closewindow}>
                                    {bye[1]}
                                </motion.a>
                            </div>
                        }
                    </div>
                </motion.div>
            </div>
        );
}

export default Thankyou;