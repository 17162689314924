import React from 'react';
import PinBlue from '../../Images/pinblue.png';

const About = ( {changelang} ) => {
    return (
        <div className= "about">
            <img src= {PinBlue} alt= "Pin Icon" />
            <div className= "abouttext">
                {changelang ? "" : "Το "}
                <b>Sort-it!</b>
                {changelang ?
                    "is a free web application that implements the card sorting tool"
                :
                    " είναι μια δωρεάν διαδικτυακή εφαρμογή που υλοποιεί το εργαλείο ταξινόμησης καρτών"
                }.
                <br />
                <br />
                {changelang ?
                    "This application was developed as a diploma thesis of the department of "
                :
                    "Η παρούσα εφαρμογή αναπτύχθηκε ως διπλωματική εργασία του τμήματος "
                }
                <a href= "https://www.ece.upatras.gr/index.php/el/" target= "_blank" rel="noreferrer">
                {changelang ?
                    "Electrical and Computer Engineering"
                :
                    "Ηλεκτρολόγων Μηχανικών και Τεχνολογίας Υπολογιστών"
                }</a>
                {changelang ?
                    " of the University of Patras in order to become familiar with the Card Sorting tool"
                :
                    " του Πανεπιστημίου Πατρών με σκοπό την εξοικείωση με το εργαλείο του Card Sorting"
                }.
                <br />
                <br />
                {changelang ?
                    "Therefore, there is no profit and the data collected is used solely to improve the user experience"
                :
                    "Επομένως, δεν υπάρχει κέρδος και τα δεδομένα που συλλέγονται χρησιμοποιούνται αποκλειστικά για τη βελτίωση της εμπειρίας των χρηστών"
                }.
                <br />
                <br />
                <div style= {{fontWeight: 'bold', textAlign: 'center'}}>
                    {changelang ? "Contact" : "Επικοινωνία"}
                </div>
                <br />
                {changelang ?
                    "For any problem please "
                :
                    "Για οποιοδήποτε πρόβλημα επικοινωνήστε στο "
                }email: <b>sortitapp@hotmail.com </b>.
                <br />
                <br />
                <div className= "attribution">
                    <span style= {{fontWeight: 'bold'}}>Credits</span>
                    <br />
                    {changelang ? "Paper image from: " : "Εικόνα χαρτιού από: "}
                    <a href= "http://www.freepik.com/" target= "_blank" rel="noreferrer">freepik</a>
                    <br />
                    {changelang ? "Icons from: " : "Εικονίδια από: "}
                    <a href= "https://www.flaticon.com/" target= "_blank" rel="noreferrer">flaticon</a>
                </div>
            </div>
        </div>
    )
}

export default About;