import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../Results/Results.css';
import { useExperimentContext } from '../../Hooks/useExperimentContext';
import { useResultsContext } from '../../Hooks/useResultsContext';
import CRC32 from "crc-32";

const Results = ( {setSure, setTheexp, setPop, changelang} ) => {
    const { id } = useParams();
    
    const { experiment, dispatch } = useExperimentContext();
    const { res, dispatchR } = useResultsContext();
    // const [res, setRes] = useState(null);
    
    const [nofcat, setNofcat] = useState(0);
    const [nofcom, setNofcom] = useState(0);

    const [check, setCheck] = useState(0);

    useEffect(() => {
        const fetchExperiment = async () => {
            const response = await fetch(`/api/dashboard/${id}`);
            const json = await response.json();

            if (response.ok) {
                dispatch({type: 'SET_EXPERIMENT', payload: json});

                const fetchResult = async () => {
                    const response2 = await fetch(`/api/dashboard/${id}/results`);
                    const json2 = await response2.json();
        
                    if (response2.ok) {
                        // setRes(json);
                        dispatchR({type: 'SET_RESULT', payload: json2})
                        
                        var previousid= 0;
                        var previousname= "";
                        var previoususer= "";
                        var nofcat= 0;
                        var nofcom= 0;

                        json2.map(jitem => {
                            if (json.type === 'open') {
                                jitem.data.map(item => {
                                    if ((((item.cat_name === previousname) && (item.cat_id === previousid) 
                                    && (jitem._id === previoususer)) ||  (item.cat_id === ""))) {
                                        nofcat+= 0;
                                    }
                                    else {
                                        nofcat+= 1;
                                    };
                                    previousid= item.cat_id;
                                    previousname= item.cat_name;
                                    previoususer= jitem._id;
                                    return true;
                                });
                            }
                            else if (json.type === 'close') {
                                nofcat= Object.keys(json.categories).length;
                            };
                            if (jitem.comments !== "") {
                                nofcom+= 1;
                            };
                            return true;
                        });
                        setNofcat(nofcat);
                        setNofcom(nofcom);
                    }
                    else {
                        setPop(14);
                    };
                };
        
                fetchResult();
            }
            else {
                window.location.pathname= "/dashboard";
            };
        };

        fetchExperiment();
    }, [check, dispatch, dispatchR, id])

    var text1= "card_id,card_label,category_id,category_label,user_id\r\n";
    var text2= "comments,user_id\r\n";
    var text= "";
    var filename= "";

    const deleteres = () => {
        var el = document.querySelector('input[name="expfile"]:checked');
        if (el) {
            el.checked= false;
        };
        setSure(3);
        setTheexp(id);
    };

    const makecsv = () => {
        var counter= 0;
        var previousid= 0;
        var previousname= "";
        var previoususer= "";
        var previousArray= [];

        res.map(items => {
            items.data.map(item => {
                text= text + item.card_id + ",";
                text= text + item.card_name + ",";
                if (experiment.type === 'close') {
                    if (counter < Object.keys(experiment.categories).length) {
                        if (item.cat_name !== previousname) {
                            counter+= 1;
                            previousArray= [...previousArray, item.cat_name];
                        };
                    }
                    else {
                        counter= previousArray.indexOf(item.cat_name) + 1;
                    };
                }
                else {
                    if ((((item.cat_name === previousname) && (item.cat_id === previousid) 
                    && (items._id === previoususer)) ||  (item.cat_id === ""))) {
                        counter+= 0;
                    }
                    else if (experiment.type === 'open') {
                        counter+= 1;
                    };
                };
                text= text + ((item.cat_name !== "") ? counter : "") + ",";
                text= text + item.cat_name + ",";
                text= text + Math.abs(CRC32.str(items._id));
                text= text + "\r\n";
                previousid= item.cat_id;
                previousname= item.cat_name;
                previoususer= items._id;
                return counter;
            });
            return text;
        });
        console.log(text);
    };

    const makecom = () => {
        res.map(item => {
            text= text + item.comments + ",";
            text= text + item._id;
            text= text + "\r\n";
            return text;
        });
        // console.log(text);
    };

    const maketext = () => {
        var el = document.querySelector('input[name="expfile"]:checked').value;

        if (el === "csv") {
            filename= experiment.name + "-" + experiment.type + ".csv";
            text= text1;
            makecsv();
        }
        else if (el === "text") {
            filename= experiment.name + "-" + experiment.type + ".txt";
            text= text1;
            makecsv();
        }
        else {
            filename= experiment.name + "-comments.csv";
            text= text2;
            makecom();
        };
    };

    const checkagain = () => {
        setCheck(check + 1);
    };

    const downloadfile = (e) => {
        e.preventDefault();
        const link = document.createElement("a");
        const file = new Blob([text], { type: 'text/plain' });
        link.href = URL.createObjectURL(file);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
    };

    return (
        <div className= "results" id= "containerdscrollbar">
            {res && <button className= "resdelete" onClick= {deleteres}>
                {changelang ? "Delete results" : "Διαγραφή αποτελεσμάτων"}
            </button>}
            {res && <div className= "resinfo">
                <div className= "respost" id= "participants">
                    <div className= "resposttext">
                        {changelang ? "Participants:" : "Συμμετέχοντες:"}
                        <br />
                        {res.length}
                    </div>
                </div>
                <div className= "respost" id= "categories">
                    <div className= "resposttext">
                        {changelang ? "Categories:" : "Κατηγορίες:"}
                        <br />
                        {(res.length !== 0) ? nofcat : 0}
                    </div>
                    </div>
                <div className= "respost" id= "comments">
                    <div className= "resposttext">
                        {changelang ? "Comments:" : "Σχόλια:"}
                        <br />
                        {(res.length !== 0) ? nofcom : 0}
                    </div>
                </div>
            </div>}
            {res && <div className= "resexport">
                <div id= "resexp">
                    {changelang ? "Export as:" : "Εξαγωγή ως:"}
                </div>
                <form className= "resradio" onSubmit= {downloadfile}>
                    <div className= "resradiobut" onClick= {checkagain}>
                        <input type="radio" id="rescsv" name= "expfile" value="csv" required />
                        <label htmlFor= "rescsv" className= "radiobut" >
                            CSV
                        </label>
                    </div>
                    <div className= "resradiobut" onClick= {checkagain}>
                        <input type="radio" id="restext" name= "expfile" value="text" required />
                        <label htmlFor= "restext" className= "radiobut" >
                            Txt
                        </label>
                    </div>
                    <div className= "resradiobut" onClick= {checkagain}>
                        <input type="radio" id="rescom" name= "expfile" value="comments" required />
                        <label htmlFor= "rescom" className= "radiobut" >
                            {changelang ? "Comments" : "Σχόλια"} (.csv)
                        </label>
                    </div>
                    <input type= "submit" value= {changelang ? "Export" : "Εξαγωγή"} id= "resdownload" 
                        onClick= {maketext} />
                </form>
            </div>}
        </div>
    )
}

export default Results;
