import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useExperimentContext } from '../../Hooks/useExperimentContext';
import { useExperimentsContext } from '../../Hooks/useExperimentsContext';
import { useUserContext } from '../../Hooks/useUserContext';
import DatabaseISO from '../functions/DatabaseISO';
import DateISO from '../functions/DateISO';

const General = ( {setPop, created, changelang} ) => {
    const { user } = useUserContext();
    const { experiment, dispatch } = useExperimentContext();
    const { dispatchX } = useExperimentsContext();
    // console.log(experiment);
    // console.log(created);
    
    const [inputName, setInputName] = useState('');
    const [redName, setRedName] = useState(false);
    const [redType, setRedType] = useState(false);

    const [req, setReq] = useState(false);
    const [redDate, setRedDate] = useState(false);
    
    const [publish, setPublish] = useState(false);

    useEffect(() => {
        if (experiment && !created) {
            setInputName(experiment.name);
            document.querySelector('select[name="type"]').value= experiment.type;
            
            if (experiment.datestart) {
                const ds1= DatabaseISO(`${experiment.datestart}`);
                const ds2= DatabaseISO(`${experiment.datestop}`);
                document.getElementById("datestart").value= ds1.replace('Z', '');
                document.getElementById("datestop").value= ds2.replace('Z', '');

                const now= DateISO();
                const d1= now >= experiment.datestart;
                const d2= now <= experiment.datestop;

                if (d1 && d2) {
                    setPublish(true);
                    dispatch({type: 'UPDATE_EXPERIMENT', payload: {publish: true}});
                }
                else {
                    setPublish(false);
                    dispatch({type: 'UPDATE_EXPERIMENT', payload: {publish: false}});
                };
            }
            else {
                setPublish(experiment.publish);
            };
        };
    }, [created, dispatch]);

    const inputNameHandler = (e) => {
        setInputName(e.target.value);
        setRedName(false);
    };

    const changeBorder = () => {
        setRedType(false);
    };

    useEffect(() => {
        if (experiment && publish) {
            document.getElementById('explink').href= `http://snf-884346.vm.okeanos.grnet.gr:3300/sort/${experiment._id}`;
        };
    }, [experiment, publish]);

    const toPublish = async () => {
        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;
        const d3= now < experiment.datestart;
        const d4= now >= experiment.datestop;

        if (!publish && (d1 && d2)) {
            setPublish(!publish);

            setPop(12);
        }
        else if (publish && (d3 || d4)) {
            setPublish(!publish);

            setPop(13);
        }
        else {
            var nexperiment= {};
            if (experiment.datestart) {
                nexperiment = {publish: !publish, datestart: "", datestop: ""};
            }
            else {
                nexperiment = {publish: !publish};
            };

            const response = await fetch(`/api/dashboard/${experiment._id}`, {
                method: 'PATCH',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();
    
            if (response.ok) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: json});

                if (experiment.datestart) {
                    document.getElementById("datestart").value= "";
                    document.getElementById("datestop").value= "";
                };

                setPublish(!publish);
            }
            else {
                setPop(15);
            };
        }
    };

    const check = (e) => {
        if (inputName === "") {
            e.preventDefault();
            setRedName(true);
        };

        var el = document.querySelector('select[name="type"] option:checked').value;
        if (el === "") {
            e.preventDefault();
            setRedType(true);
        };

        const d1= document.getElementById("datestart").value;
        const d2= document.getElementById("datestop").value;
        
        if ((d1 !== "") || (d2 !== "")) {
            setReq(true);
        }
        else {
            setReq(false);
        };
        if ((d2 <= d1) && (d2 !== "")) {
            e.preventDefault();
            setRedDate(true);
        };
        // if (publish) {
        //     e.preventDefault();
        // };
    };
    
    const navigate= useNavigate();
    
    const changedata = async (e) => {
        e.preventDefault();
        
        var el = document.querySelector('select[name="type"] option:checked').value;

        if (created) {
            const d1= document.getElementById("datestart").value;
            const d2= document.getElementById("datestop").value;

            const nexperiment = {name: inputName, type: el, datestart: d1, datestop: d2, user_id: user};

            const response = await fetch('/api/dashboard', {
                method: 'POST',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();
            
            if (response.ok) {
                setPop(5);
                // setCreated(false);
                window.history.pushState('', '', `/dashboard/${json._id}`);
                navigate(`/dashboard/${json._id}`);
                dispatchX({type: 'CREATE_EXPERIMENT', payload: json});
            }
            else {
                if (response.status === 401) {
                    setPop(16);
                }
                else {
                    setPop(15);
                };
            };
        }
        else {
            const now= DateISO();
            const d1= now >= experiment.datestart;
            const d2= now <= experiment.datestop;

            if ((experiment.publish === true) || (d1 && d2)) {
                setPop(11);
            }
            else {
                const d1= document.getElementById("datestart").value;
                const d2= document.getElementById("datestop").value;

                var nexperiment = {};

                if (el === "close") {
                    nexperiment = {name: inputName, type: el, datestart: d1, datestop: d2};
                }
                else {
                    nexperiment = {name: inputName, type: el, datestart: d1, datestop: d2, categories: {}}
                };
    
                const response = await fetch(`/api/dashboard/${experiment._id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(nexperiment),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const json = await response.json();
                
                if (response.ok) {
                    dispatch({type: 'UPDATE_EXPERIMENT', payload: json});

                    const d3= now >= json.datestart;
                    const d4= now <= json.datestop;

                    if ((json.publish === true) || (d3 && d4)) {
                        setPublish(true);
                    }
                    else {
                        setPublish(false);
                    };
                    
                    setPop(6);
                }
                else {
                    setPop(15);
                };
            };
        };
    };
 
    return (
        <form className= "general" id= "containerdscrollbar" onSubmit= {changedata}>
            <div className= "infoside">
                {publish && <div id= "isonline">
                    {changelang ? 
                        "When the experiment is online it cannot be modified!" 
                    :
                        "Όταν το πείραμα είναι online δεν μπορεί να τροποποιηθεί!"
                    }
                    <br />
                    {changelang ? 
                        "***If you disable the link while it is published, issues may arise when collecting the results***" 
                    :
                        "***Αν απενεργοποιήσετε το link ενώ έχει δημοσιοποιηθεί, μπορεί να δημιουργηθούν θέματα στη συλλογή των αποτελεσμάτων***"
                    }
                </div>}
                <div className= "infocat">
                    <div className= "infoname">
                        <span style= {{color: 'red'}}>*</span>
                        {changelang ? "Experiment name:" : "Όνομα πειράματος:"}
                        </div>
                    <input className= "expform" type= "text"
                        title= {inputName} value= {inputName} autoComplete= "off" disabled= {publish}
                        onChange= {inputNameHandler} style= {{borderColor: (redName? 'red' : 'black')}} />
                </div>
                <div className= "infocat">
                    <div className= "infotype">
                        <span style= {{color: 'red'}}>*</span>
                        {changelang ? "Type of" : "Είδος"} Card Sorting:
                    </div>
                    <select name="type" className= "expform" id="type" defaultValue= "" required 
                        disabled= {publish} style= {{borderColor: (redType? 'red' : 'black')}} onChange= {changeBorder} >
                            <option value="" disabled hidden></option>
                            <option value="open">{changelang ? "Open" : "Ανοιχτό"}</option>
                            <option value="close">{changelang ? "Close" : "Κλειστό"}</option>
                    </select>
                </div>
                {(redName || redType) && <div id= "errormessage">
                    {changelang ? "*Fill in the required fields!*" : "*Συμπληρώστε τα απαραίτητα πεδία!*"}
                </div>}
            </div>
            <div className= "generateside">
            <div className= "infocat">
                <div className= "infostart">{changelang ? "Start:" : "Έναρξη:"}</div>
                    <input className= "expform" type= "datetime-local" id= "datestart" disabled= {publish} 
                        required= {req} />
                <div className= "infostop">{changelang ? "End:" : "Λήξη:"}</div>
                    <input className= "expform" type= "datetime-local" id= "datestop" disabled= {publish} 
                        required= {req} style= {{borderColor: (redDate? 'red' : 'black')}} 
                        onChange= {() => {setRedDate(false);}} />
                    {redDate && <div id= "errormessage">
                        {changelang ? 
                            "*The end date must be after the start date!*" 
                        :
                            "*Η ημερομηνία λήξης πρέπει να είναι μετά την ημερομηνία έναρξης!*"
                        }
                    </div>}
                </div>
                <div className= "infocat alt">
                    <div id= "alttext">{changelang ? "Alternatively:" : "Εναλλακτικά:"}</div>
                    <button type= "button" id= "publishbutton" onClick= {toPublish} disabled= {created}
                        style= {{backgroundColor: (publish ? "Green" : "Red")}}>
                        {publish ? 
                            (changelang ? "Disable Link" : "Απενεργοποίηση Link") 
                        : 
                            (changelang ? "Activate Link" : "Ενεργοποίηση Link")
                        }
                    </button>
                </div>
                {publish && <span>
                    <a href= "link" target="_blank" rel="noopener noreferrer" id= "explink">
                        http://snf-884346.vm.okeanos.grnet.gr:3300/sort/{experiment._id}
                    </a>
                </span>}
            </div>
            <div className= "generalbutton">
                <input type= "submit" className= "savebutton" value= {changelang ? "Save" : "Αποθήκευση" } 
                    onClick= {check} />
            </div>
        </form>
    )
}

export default General;
