import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Explore from './Explore';
import General from './General';
import Options from './Options';
import AddCards from './AddCards';
import AddCategories from './AddCategories';
import './Createdit.css';
import { useExperimentContext } from '../../Hooks/useExperimentContext';

const Createdit = ( {setPop, changelang} ) => {
    const { id } = useParams();
    // console.log(id)

    var created= false;

    if (window.location.pathname === '/dashboard/create') {
        created= true;
    };
    // else if (experiment === undefined) {
    //     window.location.pathname= "/dashboard";
    // }
    // else if (experiment.user_id !== user.user_id) {
    //     window.location.pathname= "/dashboard";
    // };
    
    // const [created, setCreated]= useState(create);

    // const [experiment, setExperiment] = useState(null);
    const { experiment, dispatch } = useExperimentContext();

    useEffect(() => {
        if (!created) {
            const fetchExperiment = async () => {
                const response = await fetch(`/api/dashboard/${id}`);
                const json = await response.json();
    
                if (response.ok) {
                    // setExperiment(json);
                    dispatch({type: 'SET_EXPERIMENT', payload: json});
                }
                else {
                    window.location.pathname= "/dashboard";
                };
            };
    
            fetchExperiment();
        };
    }, [created, dispatch, id])

    return (
        <div className= "createdit">
            {(experiment || created) && <Explore created= {created} changelang= {changelang} />}
            <Routes>
                {(experiment || created) && 
                <Route path= "/" element= {<General setPop= {setPop} created= {created} changelang= {changelang} />} />}
                {experiment && 
                <Route path= "/options" element= {<Options setPop= {setPop} changelang= {changelang} />} />}
                {experiment && 
                <Route path= "/cards" element= {<AddCards setPop= {setPop} changelang= {changelang} />} />}
                {(experiment && (experiment.type === 'close')) && 
                <Route path= "/categories" element= {<AddCategories setPop= {setPop} changelang= {changelang} />} />}
                {experiment && 
                <Route path= "*" element= {<Navigate to={`/dashboard/${id}`} replace />} />}
            </Routes>
        </div>
    )
}

export default Createdit;