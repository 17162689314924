import { useState } from 'react';

export const useForgot = () => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const forgot = async (email, setPop) => {
        setIsLoading(true);
        setError(null);

        const cuser = { email };

        const response = await fetch('/forgotpassword', {
            method: 'POST',
            body: JSON.stringify(cuser),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            // save user to localstorage
            // localStorage.setItem('user', JSON.stringify(true));

            // dispatchA({type: 'LOGIN', payload: json});

            setIsLoading(false);
            setPop(2);

            setTimeout(function() {
                window.location.assign('/');
            }, 1300);
        }
        else {
            setIsLoading(false);

            if (json.error.includes('valid')) {
                setError('Μη έγκυρο email!');
            }
            else if (json.error.includes('User')) {
                setError('Δεν υπάρχει αυτό το email!');
            }
            else {
                setError(json.error);
            };
        };
    }
    return { forgot, isLoading, error };
}