import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Sort from './Sort';
import CloseSort from './CloseSort';
import DateISO from '../functions/DateISO';

const ChooseSort = () => {
    const { id } = useParams();

    const [exp, setExp] = useState(null);

    const [cards, setCards] = useState(null);
    
    const [categories, setCategories] = useState(null);
    const [categoriesOrder, setCategoriesOrder] = useState(null);

    useEffect(() => {
        const fetchExperiment = async () => {
            const response = await fetch(`/api/sort/${id}`);
            const json = await response.json();

            if (response.ok) {
                const now= DateISO();
                const d1= now >= json.datestart;
                const d2= now <= json.datestop;

                if ((json.publish === true) || (d1 && d2)) {
                    setExp(json);
                    // const data= JSON.parse(window.localStorage.getItem(`${json._id}`));
                    // console.log(data)
                
                    // if (data !== null) {
                    //     setCards(data.cards);
                    //     setCategories(data.categories);
                    //     setCategoriesOrder(data.categoriesOrder);
                    //     if (json.type === 'close') {
                    //         setCategories(data.categories);
                    //         setCategoriesOrder(data.categoriesOrder);
                    //     }
                    //     else {
                    //         setCategories({});
                    //         setCategoriesOrder({ order: [] });
                    //     };
                    // }
                    // else {
                        setCards(json.cards);
        
                        if (json.randomizecards === true) {
                            RandomCards(json);
                        };
        
                        if (json.type === 'close') {
                            if (json.randomizecategories === true) {
                                RandomCategories(json);
                            }
                            else {
                                var allthecategories= {};
                                Object.values(json.categories).map((item, index) => {
                                    const thecategory=  {[`category-${index + 1}`] : { id: `category-${index + 1}`, name: item.name, cardId: [], minimize: false}};
                                    allthecategories= {...allthecategories, ...thecategory};
                                    return allthecategories;
                                });
                                setCategories(allthecategories);
                            };
        
                            var theorder= [];
                            Object.keys(json.categories).map((item, index) => {
                                theorder.push(`category-${index + 1}`);
                                return theorder;
                            });
                            setCategoriesOrder({...categoriesOrder, order: theorder});
                        }
                        else {
                            setCategories({});
                            setCategoriesOrder({ order: [] });
                        };
                    // };  
                }
                else {
                    window.location.pathname= "/notfound";
                };
            }
            else {
                window.location.pathname= "/notfound";
            };
        }
        
        fetchExperiment();
    },[]);

    // useEffect(() => {
    //     console.log(exp)
    //     console.log(cards)
    //     console.log(categories)
    //     console.log(categoriesOrder)
    // }, [exp, cards, categories, categoriesOrder])

    const RandomCards = (exp) => {
        let shuffled = Object.values(exp.cards).map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
        var finalcards= {};
    
        // console.log(shuffled);
        shuffled.map((item, index) => {
            const finalcard=  {[`card-${index + 1}`] : { id: `card-${index + 1}`, name: item.name}};
            finalcards= {...finalcards, ...finalcard};
            return finalcards;
        });
        setCards(finalcards);
    };

    const RandomCategories = (exp) => {
        let shuffled = Object.values(exp.categories).map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
        var finalcategories= {};
        var finalcategory= {};
    
        // console.log(shuffled);
        shuffled.map((item, index) => {
            finalcategory=  {[`category-${index + 1}`] : { id: `category-${index + 1}`, name: item.name, cardId: [], minimize: false}};
            finalcategories= {...finalcategories, ...finalcategory};
            return finalcategories;
        });
        setCategories(finalcategories);
    };

    return (
        <Routes>
            <Route path= "/" element= {exp && cards && categories && categoriesOrder 
            ? 
                ((exp.type === 'open') 
                ? 
                <Sort exp= {exp} cards= {cards} setCards= {setCards} /> 
                : 
                <CloseSort  exp= {exp} cards= {cards} setCards= {setCards} 
                    categories= {categories} setCategories= {setCategories} 
                    categoriesOrder= {categoriesOrder} setCategoriesOrder= {setCategoriesOrder} />) 
            : 
            null } />
            <Route path="*" element= {<Navigate to={`/sort/${id}`} replace />} />
        </Routes>
    )
}

export default ChooseSort;