import React from 'react';
import { motion } from 'framer-motion';
import './Errors.css';

const buttonVariants= {
    animate: {
        scale: 1.2
    },
    transition: { duration: 0.5, yoyo: Infinity }
}

const NotExperiment = () => {
    const returnHome = () => {
        window.location.assign('/home');
    };

    return (
        <div className= "errors">
            <div className= "notexperiment">
                <p>
                    Το πείραμα δε βρέθηκε!
                </p>
                <br />
                <p>
                    Experiment not found!
                </p>
                <motion.button id= "tohome" onClick= {returnHome} {...buttonVariants}>
                </motion.button>
            </div>
        </div>
    )
}

export default NotExperiment;