import React from 'react';
import { motion } from 'framer-motion';
import Greek from '../../Images/greekflag.png';
import UK from '../../Images/ukflag.png';

const tasksVariants = {
    initial: {
        scale: 0
    },
    animate: {
        scale: 1,
        transition: { duration: 0.3 }
    },
    exit: {
        scale: 0, 
        transition: { duration: 0.2 }
    }
}

const Tasks = ( {setTasks, setInstructions, task, changelang, setChangelang} ) => {

    const changeLanguage = () => {
        changelang ? setChangelang(false) : setChangelang(true);
    };

    const beginsort = () => {
        if (task !== "") {
            const html= `
            <html lang="en">
                <head>
                    <meta charset="UTF-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap" rel="stylesheet"/>
                    <title>Οδηγίες/Instructions</title>
                    <style>
                    body {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        color: black;
                        background-color: rgb(228, 210, 202);
                        font-family: "Roboto", sans-serif;
                    }
                    .container {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .note {
                        padding: 0 30px 30px 30px;
                        background-color: rgb(255, 255, 104);
                        border: 2px solid black;
                        border-radius: 2vh;
                        box-shadow: 0 0 15px rgb(100, 23, 41);
                    }
                    h2 {
                        font-weight: bolder;
                        text-decoration: underline;
                    }
                    p {
                        white-space: pre-line;
                    }
                    button {
                        padding: 15px 70px 15px 70px;
                        border-radius: 5px;
                        border: 0;
                        color: white;
                        background-color: rgb(100, 23, 41);
                        transition: all 0.3s ease-in;
                        cursor: pointer;
                    }
                    button:hover {
                        color: black;
                        background-color: rgb(216, 172, 151);
                        transition: all 0.3s ease-in;
                    }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <div class= "note">
                            <h2>Οδηγίες πειράματος / Experiment Instructions</h2>
                            <p>${task}</p>
                            <br/>
                            <br/>
                            <button onclick="self.close()">Κλείσιμο / Close</button>
                        </div>
                    </div>
                </body>
            </html>
            `

            const link = document.createElement("a");
            const file = new Blob([html], { type: 'text/html;charset=UTF-8' });
            link.href = URL.createObjectURL(file);
            link.target = "_blank";
            link.click();
            URL.revokeObjectURL(link.href);
        };
        setTasks(false);
        setInstructions(true);
    };

    return(
        <div className= "tasks">
            <span id= "languaget" onClick= {changeLanguage} lang= {changelang ? "greek" : "english"}
                title= {changelang ? "Change language": "Αλλαγή Γλώσσας"} >
                {changelang ? 
                    <img src= {Greek} alt= "Greek Flag Icon" lang= "greek"
                        style= {{width: '20px', height: '16px'}} /> 
                : 
                    <img src= {UK} alt= "UK Flag Icon" lang= "english"
                        style= {{width: '20px', height: '16px'}} />
                }    
            </span>
            <motion.div className= "taskscontainer" {...tasksVariants}>
                    <p style= {{fontSize: '1.5em', fontWeight: 'bold'}}>
                        {changelang ? "Welcome to Sort-it!" : "Καλωσήρθατε στο Sort-it!"}
                    </p>
                    <br/>
                    <p style= {{fontSize: '1.2em'}}>
                        {(task === "") ?
                            (changelang ?
                                "Press the link below to move to the card sorting page:"
                            : 
                                "Πατήστε τον παρακάτω σύνδεσμο για να μεταβείτε στη σελίδα διεξαγωγής του πειράματος:"
                            )
                        :
                            (changelang ?
                                "Press the link below to view the instructions and move to the card sorting page:"
                            : 
                                "Πατήστε τον παρακάτω σύνδεσμο για να δείτε τις οδηγίες και να μεταβείτε στη σελίδα διεξαγωγής του πειράματος:"
                            )
                        }
                    </p>
                    <br/>
                    <motion.div onClick= {beginsort} whileHover= {{ scale: 1.2 }} >
                        {(task === "") ?
                            (changelang ?
                                "Start"
                            : 
                                "Ξεκινήστε"
                            )
                        :
                            (changelang ?
                                "INSTRUCTIONS"
                            : 
                                "ΟΔΗΓΙΕΣ"
                            )
                        }
                    </motion.div>
            </motion.div>
        </div>
    );
}

export default Tasks;