import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Sure from './SureC';
import Add from '../../Images/add.png';
import WhiteAdd from '../../Images/whiteadd.png';
import Delete from '../../Images/delete.png';
import Plus from '../../Images/plus.png';
import WhitePlus from '../../Images/whiteplus.png';
import x from '../../Images/x.png';
import { useExperimentContext } from '../../Hooks/useExperimentContext';
import DateISO from '../functions/DateISO';

const AddCategories = ( {setPop, changelang} ) => {
    const { experiment, dispatch } = useExperimentContext();

    const [categories, setCategories] = useState(experiment.categories);
    const [duplicate, setDuplicate] = useState(false);
    const [empty, setEmpty] = useState(false);
    const exist= (changelang ? ": Category already exists!" : ": Η κατηγορία υπάρχει ήδη!");

    const [dispImportCategories, setDispImportCategories] = useState(false);
    const [inputCategories, setInputCategories] = useState("");
    const [redCategories, setRedCategories] = useState(false);

    const [over1, setOver1] = useState(false);
    const [over2, setOver2] = useState(false);

    const [dispAddaCategory, setDispAddaCategory] = useState(false);
    const [inputCategory, setInputCategory] = useState("");
    const [redCategory, setRedCategory] = useState(false);

    const [sure, setSure] = useState();

    useEffect(() => {
        if (experiment.datestart) {
            const now= DateISO();
            const d1= now >= experiment.datestart;
            const d2= now <= experiment.datestop;

            if (d1 && d2) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: {publish: true}});
            }
            else {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: {publish: false}});
            };
        };
    }, [dispatch, experiment.datestart, experiment.datestop]);

    document.body.onpointerdown= (e) => {
        if (e.target.getAttribute('data-inadd')) {
            return;
        };
        setInputCategory("");
        setRedCategory(false);
        setDispAddaCategory(false);
    };

    const importCategories = () => {
        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            setDispImportCategories(true);
        };
    };

    const inputCategoriesHandler = (e) => {
        setInputCategories(e.target.value);
        setRedCategories(false);
        setDuplicate(false);
        setEmpty(false);
    };

    const checkall = (e) => {
        var importedCategories= inputCategories.replace(/\s+/g,' ').split(",");
        var importedCategoriesWS= [];
        importedCategories.map((item, index) => {
            importedCategories[index]= item.trim();
            return importedCategoriesWS[index]= item.replace(/\s/g,'');
        });
        if (importedCategories.includes('')) {
            e.preventDefault();
            setRedCategories(true);
            setEmpty(true);
        }
        else if ((new Set(importedCategoriesWS)).size !== importedCategoriesWS.length) {
            e.preventDefault();
            setRedCategories(true);
            setDuplicate(true);
        }
        else {
            var newCategories= [];
            importedCategories.map((item, index) => {
                var newCategory= {[`category-${index + 1}`]: { id: `category-${index + 1}`, name: item}};
                newCategories= {...newCategories, ...newCategory};
                return newCategories;
            });
            setCategories(newCategories);
        };
    };

    const addtheCategories = async (e) => {
        e.preventDefault();
        
        const nexperiment = {categories: categories};

        const response = await fetch(`/api/dashboard/${experiment._id}`, {
            method: 'PATCH',
            body: JSON.stringify(nexperiment),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            dispatch({type: 'UPDATE_EXPERIMENT', payload: json});

            setInputCategories("");
            setDispImportCategories(false);

            setPop(8);
        }
        else {
            setCategories({});
            
            setPop(15);
        };
    };

    const cancelImport = () => {
        setDispImportCategories(false);
        setInputCategories("");
        setRedCategories(false);
        setDuplicate(false);
        setEmpty(false);
    };

    const deleteAll = () => {
        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            if (!experiment.publish) {
                setSure(2);
            };
        };
    };

    const addaCategory = () => {
        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            setDispAddaCategory(true);
        };
    };

    const inputCategoryHandler = (e) => {
        setInputCategory(e.target.value);
        setRedCategory(false);
    };

    const reset = () => {
        setInputCategory(inputCategory.replace(exist, ""));
    };

    const check = (e) => {
        var cat= inputCategory.replace(/\s+/g,' ').trim();

        if ((cat === "")) {
            e.preventDefault();
            setInputCategory(cat);
            setRedCategory(true);
        }
        else {
            if (redCategory) {
                e.preventDefault();
                setInputCategory(inputCategory.replace(exist,'') + exist);
            }
            else {
                var checkValues= [];
                Object.values(categories).map((category) => {
                    return checkValues= [...checkValues, category.name];
                });
                if (checkValues.includes(cat)) {
                    e.preventDefault();
                    setRedCategory(true);
                    e.target.parentNode.childNodes[0].blur();
                    setInputCategory(cat + exist);
                };
            }
        };
    };

    const addtheCategory = async (e) => {
        e.preventDefault();
        
        var cat= inputCategory.replace(/\s+/g,' ').trim();

        var l= Object.keys(categories).length + 1;

        const newCategory= {[`category-${l}`]: {id: `category-${l}`, name: cat}};
        const finalCategories= {...categories, ...newCategory};

        const nexperiment = {categories: finalCategories};

        const response = await fetch(`/api/dashboard/${experiment._id}`, {
            method: 'PATCH',
            body: JSON.stringify(nexperiment),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            dispatch({type: 'UPDATE_EXPERIMENT', payload: json});

            setCategories(finalCategories);
            setInputCategory("");
            setDispAddaCategory(false);

            setPop(7);
        }
        else {
            setPop(15);
        };
    };

    const updatetheStates = async (e) => {
        e.preventDefault();

        const now= DateISO();
        const d1= now >= experiment.datestart;
        const d2= now <= experiment.datestop;

        if ((experiment.publish === true) || (d1 && d2)) {
            setPop(11);
        }
        else {
            var el = document.querySelector('input[name="randomizecards"]:checked');
            var rand= true;
            if (el === null) {
                rand= false;
            }
            else {
                rand= true;
            };

            const nexperiment = {randomizecategories: rand};

            const response = await fetch(`/api/dashboard/${experiment._id}`, {
                method: 'PATCH',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();

            if (response.ok) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: json});
                setPop(9);
            }
            else {
                setPop(15);
            };
        };
    };

    const deleteCategory = async (index) => {
        if (!experiment.publish) {
            const dcategories= {...categories};

            delete dcategories[`category-${index + 1}`];
            
            var finalCategories= {};
            Object.values(dcategories).map((category, index) => {
                return finalCategories= {...finalCategories, 
                    [`category-${index + 1}`] : { id: `category-${index + 1}`, name: category.name}}
            });
            
            const nexperiment = {categories: finalCategories};

            const response = await fetch(`/api/dashboard/${experiment._id}`, {
                method: 'PATCH',
                body: JSON.stringify(nexperiment),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();

            if (response.ok) {
                dispatch({type: 'UPDATE_EXPERIMENT', payload: json});

                setCategories(finalCategories);

                setPop(4);
            }
            else {
                setPop(15);
            };
        };
    };

    return (
        <div className= "addcards" id= "containerdscrollbar">
            <div className= "cardsside">
                <div className= "cardsheader">
                    <button className= "cardsbutton" id= "importcards" onClick= {importCategories}
                        onMouseOver= {() => setOver1(true)} onMouseOut= {() => setOver1(false)} 
                        disabled= {experiment.publish} >
                            <span>{changelang ? "Upload" : "Ανέβασμα"}</span>
                            <img src= {(over1 && !experiment.publish)? Add : WhiteAdd} alt= "Add Multiple Icon" />
                    </button>
                    <div id= "totalcards">
                        #
                        <span id= "totalcardstext" >
                            No:&nbsp;
                        </span>
                        {Object.keys(categories).length}
                        <div className= "iconhover" onClick= {deleteAll} data-isdisabled= {experiment.publish} 
                            title= {changelang ? "Delete all" : "Διαγραφή όλων"} >
                                <img src= {Delete} alt= "Delete Icon" />
                        </div>
                    </div>
                    <button className= "cardsbutton" id= "addcards" onClick= {addaCategory} data-inadd= {true}
                        onMouseOver= {() => setOver2(true)} onMouseOut= {() => setOver2(false)} 
                        disabled= {experiment.publish} >
                            <span>{changelang ? "Add" : "Προσθήκη"}</span>
                            <img src= {(over2 && !experiment.publish)? Plus : WhitePlus} alt= "Add Icon" />
                    </button>
                </div>
                <div className= "cardsbody" id= "containerdscrollbar">
                    {dispImportCategories && <form className= "importcards" onSubmit= {addtheCategories}>
                        <div className= "importcardscontainer">
                            <div id= "importcardsinfo">
                                {changelang ? 
                                    "Add the category names separated by" 
                                :
                                    "Προσθέστε τα ονόματα κατηγοριών διαχωρισμένα με"
                                }&nbsp;
                                <span style= {{fontWeight: "bold"}}>
                                    {changelang ? "commas" : "κόμματα"}
                                </span>.
                                <br/>
                                <span style= {{fontWeight: "bold"}}>
                                    {changelang ? "ATTENTION:" : "ΠΡΟΣΟΧΗ:"}&nbsp;
                                </span>
                                {changelang ? 
                                    "These categories will replace the existing ones!" 
                                :
                                    "Οι κατηγορίες αυτές θα αντικαταστήσουν τις υπάρχουσες!"
                                }
                            </div>
                            <textarea className= "taskstextarea" id= "containerdscrollbar" 
                                placeholder= {changelang ? 
                                    "π.χ.: Category1, Category2, Category3, etc..." 
                                : 
                                    "π.χ.: Κατηγορία1, Κατηγορία2, Κατηγορία3, etc..."
                                } 
                                value= {inputCategories} autoComplete= "off" autoFocus= "on" 
                                onChange= {inputCategoriesHandler} 
                                style= {{color: (redCategories? 'red' : 'black'), 
                                    borderColor: (redCategories? 'red' : 'black')}} >
                            </textarea>
                            {duplicate && <div id= "duplicated">
                                {changelang ? 
                                    "There are duplicate category names!" 
                                :
                                    "Υπάρχουν διπλότυπα ονόματα κατηγοριών!"
                                }
                            </div>}
                            {empty && <div id= "duplicated">
                                {changelang ? 
                                    "There are empty categories!" 
                                :
                                    "Υπάρχουν κενές κατηγορίες!"
                                }
                            </div>}
                            <div className= "importcardsbuttons">
                                <input type= "submit" className= "importcardsbutton" id= "savecardsbutton" 
                                    value= {changelang ? "Save" : "Αποθήκευση"} onClick= {checkall} />
                                <button type= "button" className= "importcardsbutton" id= "cancelimport" 
                                    onClick= {cancelImport} >
                                        {changelang ? "Cancel" : "Ακύρωση"}
                                </button>
                            </div>
                        </div>
                    </form>}
                    {dispAddaCategory && <form className= "addacard" data-inadd= {true} onSubmit= {addtheCategory}>
                        <input className= "expform" type= "text" title= {inputCategory} value= {inputCategory} 
                            autoComplete= "off" autoFocus= "on" onChange= {inputCategoryHandler} onClick= {reset}
                            style= {{color: (redCategory? 'red' : 'black'), borderColor: (redCategory? 'red' : 'black')}} data-inadd= {true} />
                        <input type= "submit" className= "savecardbutton" value= {changelang ? "Save" : "Αποθήκευση"}   
                            onClick= {check} data-inadd= {true} />
                    </form>}
                    {Object.values(categories).map((category, index) => {
                        return (
                            <div key= {category.id} className= "addedcard">
                                <span>{category.name}</span>
                                <div id= "deletecardicon" onClick= {() => deleteCategory(index)} 
                                    title= {changelang ? "Delete category" : "Διαγραφή κατηγορίας"} 
                                    data-isdisabled= {experiment.publish} >
                                        <img src= {x} alt= "Delete Card Icon" />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>       
            <div className= "settingsside">
                <form className= "settingscontainer" onSubmit= {updatetheStates}>
                    <div className= "settingscheckbox">
                        <input type="checkbox" id="randomizecards" name="randomizecards" value="randomizecards" 
                                defaultChecked= {experiment.randomizecategories} disabled= {experiment.publish} />
                        <label htmlFor= "randomizecards">
                            {changelang ?
                                "Display categories in random order." 
                            :
                                "Εμφάνιση κατηγοριών σε τυχαία σειρά."
                            }
                        </label>
                    </div>
                    <input type= "submit" className= "savecardbutton" value= {changelang ? "Update" : "Ενημέρωση"} disabled= {experiment.publish} />
                </form>
            </div>
            <AnimatePresence>
                {sure && <Sure setCategories= {setCategories} sure= {sure} setSure= {setSure} setPop= {setPop} 
                    changelang= {changelang} />}
            </AnimatePresence>
        </div>
    )
}

export default AddCategories;