import { useState } from 'react';

export const useReset = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const reset = async (password, token, setPop) => {
        setIsLoading(true);
        setError(null);

        const npas = { password };

        const response = await fetch(`/resetpassword/${token}`, {
            method: 'PATCH',
            body: JSON.stringify(npas),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            // save user to localstorage
            // localStorage.setItem('user', JSON.stringify(true));

            // dispatchA({type: 'LOGIN', payload: json});

            setIsLoading(false);

            setPop(3);
            setTimeout(function() {
                window.location.assign('/login');
            }, 1300);
        }
        else {
            setIsLoading(false);

            setError(json.error);
        };
    }
    return { reset, isLoading, error };
}