import { useContext } from 'react';
import { ExperimentContext } from '../Context/ExperimentContext';

export const useExperimentContext = () => {
    const context = useContext(ExperimentContext)

    if (!context) {
        throw Error('useExperimentContext must be used inside an ExperimentContextProvider')
    }

    return context
}