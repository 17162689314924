import { useState } from 'react';

export const useLogin = () => {

    const [valerror, setValerror] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const login = async (email, password) => {
        setIsLoading(true);
        setError(null);
        setValerror(null);

        const cuser = { email, password };

        const response = await fetch('/login', {
            method: 'POST',
            body: JSON.stringify(cuser),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();

        if (response.ok) {
            // save user to localstorage
            // localStorage.setItem('user', JSON.stringify(true));

            // dispatchA({type: 'LOGIN', payload: json});

            setIsLoading(false);

            window.location.assign('/dashboard');
        }
        else {
            setIsLoading(false);

            if (json.error.includes('valid')) {
                setValerror('Μη έγκυρο email!');
            }
            else if (json.error.includes('email')) {
                setError('Λάθος email!');
            }
            else if (json.error.includes('password')) {
                setError('Λάθος κωδικός!');
            }
            else {
                setError(json.error);
            };
        };
    }
    return { login, isLoading, valerror, error };
}