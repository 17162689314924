import React from 'react';
import { motion } from 'framer-motion';
import { useLogout } from '../../Hooks/useLogout';
import { useExperimentsContext } from '../../Hooks/useExperimentsContext';
import { useResultsContext } from '../../Hooks/useResultsContext';

const sureVariants = {
    initial: {
        scale: 0
    },
    animate: {
        scale: 1,
        transition: { duration: 0.3 }
    },
    exit: {
        scale: 0, 
        transition: { duration: 0.2 }
    }
}

const Sure = ( {theexp, sure, setSure, setPop, changelang} ) => {
    const { logout } = useLogout();

    const { dispatchX } = useExperimentsContext();
    const { dispatchR } = useResultsContext();
    
    const renderSure = () => {
        switch(sure) {
            default: return;
            case 1: return (changelang ? "log out?" : "να αποσυνδεθείτε;");
            case 2: return (changelang ? "delete this experiment?" : "διαγράψετε αυτό το πείραμα;");
            case 3: return (changelang ? "delete these results?" : "διαγράψετε αυτά τα αποτελέσματα;");
            case 4: return (changelang ? "delete your account?" : "διαγράψετε το λογαριασμό σας;");
        };
    };

    const no = () => {
        setSure(null);
    };

    const yes = async () => {
        if (sure === 1) {
            // setUserId('0');
            await logout();
        }
        else if (sure === 2) {
            const response = await fetch(`/api/dashboard/${theexp}`, {
                method: 'DELETE'
            });
            const json = await response.json();
    
            if (response.ok) {
                dispatchX({type: 'DELETE_EXPERIMENT', payload: json});
                dispatchR({type: 'DELETE_RESULT'});

                setPop(4);
            }
            else {
                setPop(15);
            };
        }
        else if (sure === 3) {
            const response = await fetch(`/api/dashboard/${theexp}/results`, {
                method: 'DELETE'
            });
            const json = await response.json();
    
            if (response.ok) {
                dispatchR({type: 'DELETE_RESULT', payload: json});
                setPop(10);
            }
            else {
                setPop(15);
            };
        }
        else if (sure === 4) {
            const response = await fetch('/api/dashboard/settings', {
                method: 'DELETE'
            });
            const json = await response.json();
    
            if (response.ok) {
                window.location.assign('/');
            }
            else {
                setPop(15);
            };
        };
        setSure(null);
    };

    return(
        <div className= "sure">
            <motion.div className= "surecontainer" {...sureVariants}>
                <div className= "suremessage">
                    {changelang ? "Are you sure you want to" : "Θέλετε σίγουρα να"}
                    <br />
                    {renderSure()}
                </div>
                <div className= "surebuttoncontainer">
                    <button className= "surebutton" id= "sno" onClick= {no}>
                        {changelang ? "No" : "Οχι"}
                    </button>
                    <button className= "surebutton" id= "syes" type= "submit" onClick= {yes}>
                        {changelang ? "YES" : "ΝΑΙ"}
                    </button>
                </div>
            </motion.div>
        </div>
    );
}

export default Sure;