import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

const popupVariants= {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    },
    exit: {
        opacity: 0,
    }
};

const PopUp = ( {pop, setPop, changelang} ) => {
    const renderPop = () => {
        switch(pop) {
            default: return;
            case 1: return (changelang ? "Logged In!" : "Συνδεθήκατε!");
            case 2: return (changelang ? "Changed!" : "Άλλαξαν!");
            case 3: return (changelang ? "Changed!" : "Άλλαξε!");
            case 4: return (changelang ? "Deleted!" : "Διαγράφηκε!");
            case 5: return (changelang ? "Created!" : "Δημιουργήθηκε!");
            case 6: return (changelang ? "Saved!" : "Αποθηκεύτηκαν!");
            case 7: return (changelang ? "Added!" : "Προστέθηκε!");
            case 8: return (changelang ? "Added!" : "Προστέθηκαν!");
            case 9: return (changelang ? "Updated!" : "Ενημερώθηκαν!");
            case 10: return (changelang ? "Deleted!" : "Διαγράφηκαν!");
            case 11: return (changelang ? "It's online!" : "Eίναι online!");
            case 12: return (changelang ? "Already online!" : "Ήδη online!");
            case 13: return (changelang ? "Already offline!" : "Ήδη ανενεργό!");
            case 14: return (changelang ? "Not loaded!" : "Δε φόρτωσαν!");
            case 15: return (changelang ? "Failed!" : "Απέτυχε!");
            case 16: return (changelang ? "Not logged in!" : "Μη συνδεδεμένος!");
        };
    };

    useEffect(() => {
        setTimeout(function() {
            setPop(false);
        }, 1000);
    }, [setPop]);

    return (
        <motion.div className= "popup d" {...popupVariants} data-pop= {pop} onClick= {() => {setPop(0);}}>
            {renderPop()}
        </motion.div>
    )
}

export default PopUp;