import React, { createContext, useReducer } from 'react';

export const ExperimentsContext = createContext()

export const experimentsReducer = (stateX, actionX) => {
    switch (actionX.type) {
        case 'SET_EXPERIMENTS':
            return {
                experiments: actionX.payload
            }
        case 'CREATE_EXPERIMENT':
            return {
                experiments: [actionX.payload, ...stateX.experiments]
            }
        case 'DELETE_EXPERIMENT':
            return {
                experiments: stateX.experiments.filter(exp => exp._id !== actionX.payload._id)
            }
        default:
            return stateX
    }
}

export const ExperimentsContextProvider = ({children}) => {
    const [stateX, dispatchX] = useReducer(experimentsReducer, {
        experiments: null
    })

    return (
        <ExperimentsContext.Provider value= {{...stateX, dispatchX}}>
            {children}
        </ExperimentsContext.Provider>
    )
}