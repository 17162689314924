import { useContext } from 'react';
import { ResultsContext } from '../Context/ResultsContext';

export const useResultsContext = () => {
    const context = useContext(ResultsContext)

    if (!context) {
        throw Error('useResultsContext must be used inside an ResultsContextProvider')
    }

    return context
}