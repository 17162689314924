
const DatabaseISO = (d) => {
    const now= new Date(d);
    const date= now.toLocaleDateString("sv-SE");
    const time= now.toLocaleTimeString("sv-SE");
    const dateTime= date + 'T' + time + 'Z';
    // console.log(now)
    // console.log(date)
    // console.log(time)
    // console.log(dateTime)
    return dateTime;
}

export default DatabaseISO;