import lang from './language.json';

const ChangeLanguageH = () => {
    var defaultLanguage = "greek";
    // console.log(lang);
    var flag = document.getElementById("languageh");
    // var flag2 = document.getElementById("languaget");

    const renderView = (data) => {
        // console.log(data);

        var home = document.getElementById("home");
        home.style.opacity= "0";
        var about = document.getElementById("about");
        about.style.opacity= "0";
        var features = document.getElementById("features");
        features.style.opacity= "0";
        var login = document.getElementById("login");
        login.style.opacity= "0";

        setTimeout(function(){
            home.innerText = data.home;
            home.style.opacity= "1";
            home.style.transition= "linear 0.10s";
            about.innerText = data.about;
            about.style.opacity= "1";
            about.style.transition= "linear 0.10s";
            features.innerText = data.features;
            features.style.opacity= "1";
            features.style.transition= "linear 0.10s";
            login.innerText = data.login;
            login.style.opacity= "1";
            login.style.transition= "linear 0.10s";
        },200)
    };

    const getData = (language) => {
        var url= lang[language];
        // console.log(url);
        renderView(url);
      };

    const handleLanguage = (event) => {
        var attr = event.target.getAttribute("lang");
        // console.log(attr)
        if (attr) {
          getData(attr);
        };
      };
    
    getData(defaultLanguage);
    flag.addEventListener('click', handleLanguage);
    // flag2.addEventListener('click', handleLanguage);

    return
}

export default ChangeLanguageH;