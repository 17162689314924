import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './HeaderD';
import Container from './Container';
import Results from '../Results/Results';
import Sure from './SureD';
import PopUp from './PopUpD';
import './Dashboard.css';
import { ExperimentsContextProvider } from '../../Context/ExperimentsContext';
import { useUserContext } from '../../Hooks/useUserContext';

const Dashboard = () => {
    const { user, dispatchU } = useUserContext();

    const [sure, setSure] = useState();

    const [theexp, setTheexp] = useState();

    const [pop, setPop] = useState(0);

    const [changelang, setChangelang] = useState(false);
    
    useEffect(() => {
        window.addEventListener('load', function() {
            if (window.location.pathname === '/dashboard') {
                setPop(1);
            };
        });
        const fetchUser = async () => {
            const response = await fetch('/api/dashboard/settings');
            const json = await response.json();

            if (response.ok) {
                dispatchU({type: 'SET_USER', payload: json})
            };
        };

        fetchUser();
        // window.localStorage.clear();
    }, []);

    // console.log(user);

    // useEffect(() => {
    //     const fetchUser = async () => {
    //         const response = await fetch('/dashboard/settings');
    //         const json = await response.json();
    //         console.log(json)

    //         if (response.ok) {
    //         }
    //         else {
    //             console.log(json)
    //         };
    //     };

    //     fetchUser();
    // }, []);

    // if (cookie === null) {
    //     return <Navigate to="/" replace />;
    // };

    // console.log(window.location.pathname);

    return (
        <div className= "dashboard">
            <Header setSure= {setSure} changelang= {changelang} setChangelang= {setChangelang} />
            <ExperimentsContextProvider>
                <Routes>
                    <Route path= "/" element= {<Container setSure= {setSure} setTheexp= {setTheexp} 
                        setPop= {setPop} changelang= {changelang} />} />
                    <Route path= "/settings" element= {<Container setSure= {setSure} setPop= {setPop} 
                        changelang= {changelang} />} />
                    <Route path= "/create/*" element= {<Container setPop= {setPop} changelang= {changelang} />} />
                    <Route path= "/:id/*" element= {<Container setPop= {setPop} changelang= {changelang} />} />
                    <Route path= "/:id/results" element= {<Results setSure= {setSure} setTheexp= {setTheexp} 
                        setPop= {setPop} changelang= {changelang} />} />
                    <Route path="*" element= {<Navigate to="/" replace />} />
                </Routes>
                <AnimatePresence>
                    {pop && <PopUp pop= {pop} setPop= {setPop} changelang= {changelang} />}
                </AnimatePresence>
                <AnimatePresence>
                    {sure && <Sure theexp= {theexp} sure= {sure} setSure= {setSure} setPop= {setPop} 
                        changelang= {changelang} />}
                </AnimatePresence>
            </ExperimentsContextProvider>
        </div>
    )
}

export default Dashboard;