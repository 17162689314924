import React, { createContext, useReducer } from 'react';

export const ResultsContext = createContext()

export const resultsReducer = (stateR, actionR) => {
    switch (actionR.type) {
        case 'SET_RESULT':
            return {
                res: actionR.payload
            }
        case 'DELETE_RESULT':
            return {
                res: []
            }
        default:
            return stateR
    }
}

export const ResultsContextProvider = ({children}) => {
    const [stateR, dispatchR] = useReducer(resultsReducer, {
        res: null
    })

    return (
        <ResultsContext.Provider value= {{...stateR, dispatchR}}>
            {children}
        </ResultsContext.Provider>
    )
}